.transition_all { transition: all 150ms ease-out; }
.transition_transform { transition: transform 300ms ease-out; }
.transition_all_slow { transition: all 500ms ease-out; }

.fade_up_slight { animation: fadeUpSlight 250ms ease-out; }
.fade_up { animation: fadeUp 250ms ease-out; }
.fade_down_slight { animation: fadeDownSlight 250ms ease-out; }
.fade_down { animation: fadeDown 250ms ease-out; }
.fade_left { animation: fadeLeft 250ms ease-out; }
.fade_left_slight { animation: fadeLeftSlight 250ms ease-out; }
.fade_right { animation: fadeRight 250ms ease-out; }
.fade_right_slight { animation: fadeRightSlight 250ms ease-out; }

.duration_xshort { animation-duration: 100ms; }
.duration_short { animation-duration: 250ms; }
.duration_medium { animation-duration: 450ms; }
.duration_long { animation-duration: 650ms; }
.duration_xlong { animation-duration: 1000ms; }

.rotate_360 {
    transform: rotate(360deg);
}

.hover_reveal {
    display: none !important;
}
.hover_revealer:hover {
    .hover_reveal { display: initial !important; }
}
.hover_raise {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        opacity: 0;
        transition: opacity 250ms ease-out;
        @extend .box_shadow_strong;
    }
    &:hover {
        &:after { opacity: 1; }
    }
}

.anim_delay_short { animation-delay: 50ms; }
.anim_delay_medium { animation-delay: 100ms; }
.anim_delay_long { animation-delay: 150ms; }
.anim_delay_xlong { animation-delay: 200ms; }

.animate_pulse {
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: inherit;
        animation: pulse 2s infinite;
    }
}

.animate_pulse_once {
    @extend .animate_pulse;
    &:after {
        animation: pulse 1s;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0;
        opacity: 0.4;
    }
    70% {
        box-shadow: 0 0 0 15px;
        opacity: 0;
    }
    100% {
        box-shadow: 0 0 0 0;
        opacity: 0;
    }
}
@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translate3d(0,50%,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeUpSlight {
    0% {
        opacity: 0;
        transform: translate3d(0,15%,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeDown {
    0% {
        opacity: 0;
        transform: translate3d(0,-50%,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeDownSlight {
    0% {
        opacity: 0;
        transform: translate3d(0,-15%,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeLeft {
    0% {
        opacity: 0;
        transform: translate3d(50%,0,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeLeftSlight {
    0% {
        opacity: 0;
        transform: translate3d(15%,0,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeRight {
    0% {
        opacity: 0;
        transform: translate3d(-50%,0,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
@keyframes fadeRightSlight {
    0% {
        opacity: 0;
        transform: translate3d(-15%,0,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}
