.short_divider {
    box-shadow: none;
    border: 0;
    width: 100%;
    height: 2px;
    @extend .bg_black;
    @extend .display_block;
    max-width: 40px;

    &.inverted {
        @extend .bg_white;
    }
    &.centered {
        @extend .mx_auto;
    }
}

.dotted_divider {
    background-image: url(/assets/images/icons/dotted.png);
    background-repeat: repeat-x;
    @extend .bg_center;
    @extend .text_center;

    & > * {
        @extend .display_inline_block;
        @extend .bg_white;
        @extend .pl_1;
        @extend .pr_1;
    }
}
