.Editor {
    @extend .display_flex;
    @extend .flex_column;
    @extend .flex_grow_1;
    @extend .flex_shrink_0;
    @extend .position_relative;
    @extend .overflow_hidden;
    
    @include media( $computer ) {
        flex-direction: row;
        height: 0;
    }
}
