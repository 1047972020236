$black  : #212B36;
$blue   : #0085DE;
$brown  : #82674c;
$olive  : #6A7B56;
$red    : #ED6347;
$white  : #FFFFFF;
$yellow : #E0AF25;
$gold : #AF9128;

$green  : #79A843;
$orange : #ef9929;
$pink   : #E9CACB;
$purple : #824AC5;
$teal   : #008571;
$violet : #CF2E95;

// MyDIYWall Brand
$primary: $blue;
$secondary: $yellow;
$accent: $green;

// Neutrals
$grey : #636466;
$lightest_grey: mix(white, $grey, 90%);
$lighter_grey: mix(white, $grey, 70%);
$light_grey: mix(white, $grey, 40%);
$dark_grey: mix(black, $grey, 25%);
$darker_grey: mix(black, $grey, 50%);
$darkest_grey: mix(black, $grey, 70%);

$color_map: (
    'accent': $purple,
    'primary': $primary,
    'negative': $red,
    'positive': $green,
    'secondary': $secondary,
    'warning': $orange,
    'black'  : $black,
    'blue'   : $blue,
    'brown'  : $brown,
    'olive'  : $olive,
    'red'    : $red,
    'white'  : $white,
    'yellow' : $yellow,
    'gold' : $gold,
    'green'  : $green,
    'orange' : $orange,
    'pink'   : $pink,
    'purple' : $purple,
    'teal'   : $teal,
    'violet' : $violet,
    'grey': $grey,
    'lightest_grey': $lightest_grey,
    'lighter_grey': $lighter_grey,
    'light_grey': $light_grey,
    'dark_grey': $dark_grey,
    'darker_grey': $darker_grey,
    'darkest_grey': $darkest_grey
);

$gradient_map: (
    'primary': linear-gradient(to bottom, mix(#fff,$primary,90%), transparent),
    'secondary': linear-gradient(to bottom, mix(#fff,$secondary,90%), transparent),
    'positive': linear-gradient(to bottom, mix(#fff,$green,90%), transparent),
);

$tint_map: (
    'primary': $primary,
    'secondary': $secondary,
);
