.hero {
	@extend .position_relative;
	@extend .bg_center;
	@extend .bg_cover;
	@extend .clearfix;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 6% 0;
	margin-bottom: $spacer * 2;
	
	@include media($tablet - 1px, max) {
		background-image: none !important;
	}
	
	&.image {
		padding: 0 0 $spacer;
		@include media($tablet) {
			padding: 10% 0;
		}
	}
	
	&.image.inverted {
		background-color: $black;
		@include media($tablet) {
			text-shadow: 0 0 5px black;
		}
	}

	&.scrim:before {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to right, rgba(white, 0.5), rgba(white, 0));
		@include media($tablet) {
			display: block;
		}
	}
	&.inverted.scrim:before {
		background-image: linear-gradient(to right, rgba(black, 0.7), rgba(black, 0.2));
	}
}

.hero__mobile_image {
	position: relative;
	margin-bottom: $spacer;
	min-height: 200px;
	height: 40vw;
	background-size: cover;
	background-position: center;

	@include media($tablet) {
		display: none !important;
	}
	
	img {
		display: block;
		width: 100%;
		height: auto;
	}
	
}