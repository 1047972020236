// Media queries
// ------------------------------------- //
@mixin media($size, $minmax: min) {
    @media only screen and (#{$minmax}-width: $size) {
        @content;
    }
}
// Clearfix
// ------------------------------------- //
@mixin clearfix {
    &:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}
