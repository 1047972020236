.ProjectMatsList {
	margin: 0 -1.5rem;

	@include media($computer) {
		margin: 0 -3rem;
	}
}

.MatColorButton {
	@extend .position_absolute;
	@extend .fade_left;
	z-index: 4;
	right: 1.5rem;

	@include media($computer) {
		position: relative;
		right: auto;
	}
}

.MatGraphic {
	@include media(#{$computer - 1px}, 'max') {
		margin-left: rem(-50);
	}
}

.MatPaletteHeader {
	@extend .display_flex;
	@extend .flex_column;
	@extend .align_items_center;
	@extend .justify_content_space_between;
}