@each $name, $min, $max in $breakpoints {
    .show_#{$name}, 
	.only_#{$name} { display: none !important }
	
	@include media( $min ) {
		.show_#{$name}, 
		.only_#{$name} { 
			display: initial !important;
			&.display_flex { display: flex !important }
			&.display_inline_flex { display: inline-flex !important }
			&.display_inline { display: inline !important }
			&.display_inline_block { display: inline-block !important }
		}
		.hide_#{$name} { display: none !important }
    }
	@include media( $max ) { 
		.only_#{$name} { display: none !important }
	}
}