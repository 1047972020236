$border-width: 1px;

@each $name, $color in $color_map {
    .border_t_#{$name} { border-top: $border-width solid $color }
    .border_r_#{$name} { border-right: $border-width solid $color }
    .border_b_#{$name} { border-bottom: $border-width solid $color }
    .border_l_#{$name} { border-left: $border-width solid $color }
    .border_#{$name} { border: $border-width solid $color }
}

.border_none { border: 0; }
.border_radius_50 { border-radius: 50% }
.border_rounded { border-radius: $border_radius }
.border_dashed { border-width: $border-width; border-style: dashed }

.border_outlined { 
    @extend .position_relative;
    &:before {
        content: "";
        @extend .position_absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        border: 4px solid white;
        pointer-events: none;
    }    
}