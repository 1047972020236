.bg_none { background: none; }
.bg_cover { background-size: cover; }
.bg_contain { background-size: contain; background-repeat: no-repeat; }
.bg_no_repeat { background-repeat: no-repeat; }
.bg_fixed { background-attachment: fixed }
.bg_100 { background-size: 100%; }

.bg_top_center { background-position: top center; }
.bg_center { background-position: center; }
.bg_bottom_center { background-position: bottom center; }

.bg_confetti { background-image: url(/assets/images/confetti@2x.png); background-size: cover; }

@each $name, $color in $color_map {
    .bg_#{$name} { background-color: $color }
    .fill_#{$name} { fill: $color; }
}

@each $name, $color in $tint_map {
    .bg_tint_#{$name} { background-color: mix(#FFF,$color,90%) }
}

@each $name, $gradient in $gradient_map {
    .bg_gradient_#{$name} { background-image: $gradient }
}

.bg_dimmer {
    background-color: rgba(14, 37, 53, 0.84);
}
