.Masthead {
	@extend .display_flex;
	@extend .align_items_center;
	@extend .flex_shrink_0;
	@extend .bg_white;
	padding: 1.5rem 1rem;

	@include media($computer) {
		padding: 1.5rem 0.5rem;
	}
}