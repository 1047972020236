#Canvas {
    box-shadow:
        rgba(0, 0, 0, 0.3) 0px 16px 32px -8px,
        rgba(0, 0, 0, 0.3) 0px 32px 64px -16px,
        rgba(0, 0, 0, 0.3) 0px 64px 128px -32px,
        rgba(0, 0, 0, 0.3) 0px 128px 256px -64px;
    outline: none;
    margin: auto;
    flex-shrink: 0;
    
    svg {
        overflow: visible;
    }
}
