.position_static { position: static; }
.position_absolute { position: absolute; }
.position_relative { position: relative; }
.position_fixed { position: fixed; }

.top_0 { top: 0%; }
.top_50 { top: 50%; }
.top_100 { top: 100%; }

.right_0 { right: 0%; }
.right_50 { right: 50%; }
.right_100 { right: 100%; }

.bottom_0 { bottom: 0%; }
.bottom_50 { bottom: 50%; }
.bottom_100 { bottom: 100%; }

.left_0 { left: 0%; }
.left_50 { left: 50%; }
.left_100 { left: 100%; }

.transform_center { left: 50%; top: 50%; transform: translate3d(-50%,-50%,0) }
.transform_center_x { left: 50%; transform: translate3d(-50%,0,0) }
.transform_center_y { top: 50%; transform: translate3d(0,-50%,0) }

.translate_left_10 { transform: translate3d(-10%,0,0); }

.z_index_-1 { z-index: -1; }
.z_index_0 { z-index: 0; }
.z_index_1 { z-index: 1; }
.z_index_100 { z-index: 100; }
.z_index_200 { z-index: 200; }
.z_index_300 { z-index: 300; }
.z_index_400 { z-index: 400; }
.z_index_500 { z-index: 500; }
.z_index_600 { z-index: 600; }
.z_index_700 { z-index: 700; }
.z_index_800 { z-index: 800; }
.z_index_900 { z-index: 900; }
.z_index_1000 { z-index: 1000; }
.z_index_2000 { z-index: 2000; }
.z_index_3000 { z-index: 3000; }
.z_index_top { z-index: 9999; }

.float_left { float: left }
.float_right { float: right }

.clearfix {
    @include clearfix;
}
