.WallcoreLogo {
	@extend .display_flex;
	@extend .align_items_center;
	@extend .position_absolute;
	@extend .top_0;
	@extend .width_100;
	@extend .left_0;
	@extend .height_100;

	.framer {
		font-size: rem(10);
	}
	
	@include media($computer) {
		justify-content: center;
		
		.logo {
			margin: auto;
		}
		.framer {
			font-size: 1rem;
		}
	}
}