.gallery_card {
	padding: 0;
	background: none;
	border: none; 
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	transition: all 300ms ease-out;
	cursor: pointer;
	
	&:hover, &:focus {
		transform: translate3d(0,-10px,0);

		.icon.search {
			opacity: 1;
			transform: translate3d(-50%, -50%, 0);
		}
	}
	
	.icon.search {
		@extend .position_absolute;
		@extend .top_50;
		@extend .left_50;
		text-shadow: 0 0 5px rgba(0,0,0,0.5);
		opacity: 0;
		transition: all 150ms ease-out;
		transform: translate3d(-50%, calc(-50% + 10px), 0);
	}
}