.outlined_button {
	@extend .border_outlined;
	&:before {
		transition: all 100ms ease-out;
	}

	&:hover, &:focus {
		&:before {
			top: -20px;
			right: -20px;
			bottom: -20px;
			left: -20px;
		}
	}
}