
.pt_16x9 {padding-top: 56%;}

// Spacing
// --------------------------------------- /
@mixin spacingClasses($i, $val) {
	.ma_#{$i} { margin: $val !important; }
	.pa_#{$i} { padding: $val !important; }
	.mx_#{$i} { margin-left: $val !important; margin-right: $val !important; }
	.px_#{$i} { padding-left: $val !important; padding-right: $val !important; }
	.my_#{$i} { margin-top: $val !important; margin-bottom: $val !important; }
	.py_#{$i} { padding-top: $val !important; padding-bottom: $val !important; }
	
	.mt_#{$i} { margin-top: $val !important; }
	.pt_#{$i} { padding-top: $val !important; }
	.mr_#{$i} { margin-right: $val !important; }
	.pr_#{$i} { padding-right: $val !important; }
	.mb_#{$i} { margin-bottom: $val !important; }
	.pb_#{$i} { padding-bottom: $val !important; }
	.ml_#{$i} { margin-left: $val !important; }
	.pl_#{$i} { padding-left: $val !important; }
}

.ma_auto { margin: auto !important; }
.mt_auto { margin-top: auto !important; }
.mb_auto { margin-bottom: auto !important; }
.mr_auto { margin-right: auto !important; }
.ml_auto { margin-left: auto !important; }
.mx_auto { margin-left: auto !important; margin-right: auto !important; }
.my_auto { margin-left: auto !important; margin-right: auto !important; }

@include spacingClasses('nudge', $spacer/3);
@include spacingClasses('half', $spacer/2);
@include spacingClasses('0', 0);

@for $i from 1 through 6 {
	$val: $spacer;

	@if 	 ( $i == 4 ) { $val: $spacer * 1.25; }
	@else if ( $i == 5 ) { $val: $spacer * 1.75; }
	@else if ( $i == 6 ) { $val: $spacer * 2.75; }

	@include spacingClasses($i, $val);

	@include media($tablet) {
		@if ( $i == 3 ) { 
			@include spacingClasses(3, $spacer * 1.25);
		}
		@if ( $i == 4 ) { 
			@include spacingClasses(4, $spacer * 1.75);
		}
		@if ( $i == 5 ) { 
			@include spacingClasses(5, $spacer * 2.75);
		}
		@if ( $i == 6 ) { 
			@include spacingClasses(6, $spacer * 4.75);
		}
	}
	
	@include media($computer) {
		@if ( $i == 2 ) { 
			@include spacingClasses(2, $spacer * 1.25);
		}
		@if ( $i == 3 ) { 
			@include spacingClasses(3, $spacer * 1.75);
		}
		@if ( $i == 4 ) { 
			@include spacingClasses(4, $spacer * 2.75);
		}
		@if ( $i == 5 ) { 
			@include spacingClasses(5, $spacer * 4.75);
		}
		@if ( $i == 6 ) { 
			@include spacingClasses(6, $spacer * 8.75);
		}
	}

}