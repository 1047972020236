.opacity_0 { opacity: 0}
.opacity_1 { opacity: 0.1}
.opacity_2 { opacity: 0.2}
.opacity_3 { opacity: 0.3}
.opacity_4 { opacity: 0.4}
.opacity_5 { opacity: 0.5}
.opacity_6 { opacity: 0.6}
.opacity_7 { opacity: 0.7}
.opacity_8 { opacity: 0.8}
.opacity_9 { opacity: 0.9}

.faded {
    @extend .opacity_6;
}

.overflow_auto { overflow: auto }
.overflow_hidden { overflow: hidden }
.overflow_x_hidden { overflow-x: hidden }
.overflow_y_hidden { overflow-y: hidden }
.overflow_scroll { overflow: scroll }
