.logo {
    background-image: url(/assets/images/mydiywalllogo.png);
    background-size: 100% auto !important;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 250ms ease-out;
    width: 190px;
    height: 27px;
    display: block;
    overflow: hidden;
    text-indent: -9999px;
    
    &.medium {
        width: 170px;
        height: 24px;
    }

    &.small {
        width: 140px;
        height: 20px;
    }

    .tagline {
        border-left: 1px solid $lighter_grey;
        padding-left: 0.7em;
        margin-left: 0.7em;
        font-size: 0.8em;
    }

}
