.flex_grow_1 { flex-grow: 1 }
.flex_grow_0 { flex-grow: 0 }
.flex_shrink_1 { flex-shrink: 1 }
.flex_shrink_0 { flex-shrink: 0 }

.align_items_center { align-items: center }
.align_items_start { align-items: flex-start }
.align_items_end { align-items: flex-end }
.align_content_center { align-content: center }

.justify_content_start { justify-content: flex-start }
.justify_content_end { justify-content: flex-end }
.justify_content_center { justify-content: center }
.justify_content_right { justify-content: right }
.justify_content_space_between { justify-content: space-between; }

.flex_wrap { flex-wrap: wrap }
.flex_column { flex-direction: column }

.flex_20 { flex: 20% }
.flex_25 { flex: 25% }
.flex_30 { flex: 30% }
.flex_33 { flex: 33.333% }
.flex_40 { flex: 40% }
.flex_50 { flex: 50% }
.flex_60 { flex: 60% }
.flex_66 { flex: 66.666% }
.flex_70 { flex: 70% }
.flex_75 { flex: 75% }
.flex_80 { flex: 80% }
.flex_100 {flex: 100%;}
