
$tablet     : 768px;
$computer   : 1000px;
$largeScreen: 1200px;
$widescreen : 1500px;

$breakpoints: (
    (mobile,         0,              $tablet - 1px ),
    (tablet,         $tablet,        $computer - 1px ),
    (computer,       $computer,      $largeScreen - 1px ),
    (large_screen,   $largeScreen,   $widescreen - 1px ),
    (widescreen,     $widescreen,    9999px ),
);

$spacer: 1.5rem;
$border_radius: 8px;