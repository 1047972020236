.MatItem {
	@extend .position_relative;
	@extend .transition_all;
	
	@include media($computer) {
		margin-top: rem(-65);
		&:first-child {
			margin-top: 0;
		}
	}
}

.MatItem__actions {
	margin: -2.5em 0 2em 1.5em;
	position: relative;
	z-index: 4;

	@include media($computer) {
		margin: 0;
		position: absolute;
		left: 3rem;
		bottom: rem(70);
		z-index: 10;
	}
}