.width_auto {width: auto;}
.height_auto {height: auto;}
.max_width_100 { max-width: 100%; }
.max_height_100 { max-height: 100%; }

@each $name, $size in $breakpoints {
    .max_width_#{$name} { max-width: $size; }
}

.width_20 { width: 20% }
.width_25 { width: 25% }
.width_30 { width: 30% }
.width_33 { width: 33.333% }
.width_40 { width: 40% }
.width_50 { width: 50% }
.width_60 { width: 60% }
.width_66 { width: 66.666% }
.width_70 { width: 70% }
.width_75 { width: 75% }
.width_80 { width: 80% }
.width_100 {width: 100%;}
.max_width_100 {max-width: 100%;}

@each $name, $size in $breakpoints {
    @include media($size) {
        .width_20_#{$name} { width: 20% }
        .width_25_#{$name} { width: 25% }
        .width_30_#{$name} { width: 30% }
        .width_33_#{$name} { width: 33.333% }
        .width_40_#{$name} { width: 40% }
        .width_50_#{$name} { width: 50% }
        .width_60_#{$name} { width: 60% }
        .width_66_#{$name} { width: 66.666% }
        .width_70_#{$name} { width: 70% }
        .width_75_#{$name} { width: 75% }
        .width_80_#{$name} { width: 80% }
        .width_100_#{$name} {width: 100%;}
    }
}

.height_100 {height: 100%;}
.max_height_100 {max-height: 100%;}
.height_auto {height: auto;}
