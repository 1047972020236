.text_left { text-align: left;}
.text_center { text-align: center;}
.text_right { text-align: right;}

@each $name, $size in $breakpoints {
    @include media($size) {
        .text_left_#{$name} { text-align: left; }
        .text_center_#{$name} { text-align: center; }
        .text_right_#{$name} { text-align: right; }
    }
}

.weight_bold { font-weight: $bold; }
.weight_normal { font-weight: $normal; }
.weight_light { font-weight: $light; }

.font_size_huge { font-size: 60px; }
.font_size_large { font-size: 30px; }
.font_size_medium { font-size: 24px; }
.font_size_small { font-size: 18px; }
.font_size_tiny { font-size: 14px; }
.font_size_normal { font-size: 1rem; }

.font_inherit { font-family: inherit; }

.text_shadow { text-shadow: 1px 2px rgba(black, 0.4) }
.uppercase { text-transform: uppercase; }
.underline { text-decoration: underline; }
.white_space_no_wrap { white-space: nowrap; }

.hide_text {
    overflow: hidden;
    text-indent: -9999px;
}

mark {
    background-color: lighten($yellow, 45%);
    color: $gold
}