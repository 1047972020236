svg.swatch {

    display: inline-block;
    width: 28px;
    height: 28px;
    vertical-align: middle;

    &.size-small {
        width: 16px;
        height: 16px;
    }
    
    &.size-large {
        width: 48px;
        height: 48px;
    }

}