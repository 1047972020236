.status_dot {
    @extend .display_inline_block;
    @extend .border_radius_50;
    width:10px;
    height:10px;

    &.new { background: $blue }
    &.approved { background: $green }
    &.materialsSent { background: $orange }
    &.framing { background: $orange }
    &.ready { background: $red }
    &.complete { background: $blue }
}
