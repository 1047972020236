@charset "UTF-8";
/**
 * Application Styles
 */
.bg_none, .StepCheckpoint {
  background: none; }

.bg_cover, .hero {
  background-size: cover; }

.bg_contain {
  background-size: contain;
  background-repeat: no-repeat; }

.bg_no_repeat {
  background-repeat: no-repeat; }

.bg_fixed {
  background-attachment: fixed; }

.bg_100 {
  background-size: 100%; }

.bg_top_center {
  background-position: top center; }

.bg_center, .dotted_divider, .hero {
  background-position: center; }

.bg_bottom_center {
  background-position: bottom center; }

.bg_confetti {
  background-image: url(/assets/images/confetti@2x.png);
  background-size: cover; }

.bg_accent {
  background-color: #824AC5; }

.fill_accent {
  fill: #824AC5; }

.bg_primary {
  background-color: #0085DE; }

.fill_primary {
  fill: #0085DE; }

.bg_negative {
  background-color: #ED6347; }

.fill_negative {
  fill: #ED6347; }

.bg_positive {
  background-color: #79A843; }

.fill_positive {
  fill: #79A843; }

.bg_secondary {
  background-color: #E0AF25; }

.fill_secondary {
  fill: #E0AF25; }

.bg_warning {
  background-color: #ef9929; }

.fill_warning {
  fill: #ef9929; }

.bg_black, .short_divider, .Sidebar__navigation, .Steps {
  background-color: #212B36; }

.fill_black {
  fill: #212B36; }

.bg_blue {
  background-color: #0085DE; }

.fill_blue {
  fill: #0085DE; }

.bg_brown {
  background-color: #82674c; }

.fill_brown {
  fill: #82674c; }

.bg_olive {
  background-color: #6A7B56; }

.fill_olive {
  fill: #6A7B56; }

.bg_red {
  background-color: #ED6347; }

.fill_red {
  fill: #ED6347; }

.bg_white, .short_divider.inverted, .dotted_divider > *, .LibraryImage.selected .LibraryImage__checkbox, .Masthead, .Sidebar, .Section, .Section__taskbar, .StepCheckpoint.active .label {
  background-color: #FFFFFF; }

.fill_white {
  fill: #FFFFFF; }

.bg_yellow {
  background-color: #E0AF25; }

.fill_yellow {
  fill: #E0AF25; }

.bg_gold {
  background-color: #AF9128; }

.fill_gold {
  fill: #AF9128; }

.bg_green {
  background-color: #79A843; }

.fill_green {
  fill: #79A843; }

.bg_orange {
  background-color: #ef9929; }

.fill_orange {
  fill: #ef9929; }

.bg_pink {
  background-color: #E9CACB; }

.fill_pink {
  fill: #E9CACB; }

.bg_purple {
  background-color: #824AC5; }

.fill_purple {
  fill: #824AC5; }

.bg_teal {
  background-color: #008571; }

.fill_teal {
  fill: #008571; }

.bg_violet {
  background-color: #CF2E95; }

.fill_violet {
  fill: #CF2E95; }

.bg_grey {
  background-color: #636466; }

.fill_grey {
  fill: #636466; }

.bg_lightest_grey, .Workspace {
  background-color: #eff0f0; }

.fill_lightest_grey {
  fill: #eff0f0; }

.bg_lighter_grey {
  background-color: #d0d1d1; }

.fill_lighter_grey {
  fill: #d0d1d1; }

.bg_light_grey {
  background-color: #a1a2a3; }

.fill_light_grey {
  fill: #a1a2a3; }

.bg_dark_grey {
  background-color: #4a4b4d; }

.fill_dark_grey {
  fill: #4a4b4d; }

.bg_darker_grey {
  background-color: #323233; }

.fill_darker_grey {
  fill: #323233; }

.bg_darkest_grey {
  background-color: #1e1e1f; }

.fill_darkest_grey {
  fill: #1e1e1f; }

.bg_tint_primary {
  background-color: #e6f3fc; }

.bg_tint_secondary {
  background-color: #fcf7e9; }

.bg_gradient_primary {
  background-image: linear-gradient(to bottom, #e6f3fc, transparent); }

.bg_gradient_secondary {
  background-image: linear-gradient(to bottom, #fcf7e9, transparent); }

.bg_gradient_positive {
  background-image: linear-gradient(to bottom, #f2f6ec, transparent); }

.bg_dimmer {
  background-color: rgba(14, 37, 53, 0.84); }

.border_t_accent {
  border-top: 1px solid #824AC5; }

.border_r_accent {
  border-right: 1px solid #824AC5; }

.border_b_accent {
  border-bottom: 1px solid #824AC5; }

.border_l_accent {
  border-left: 1px solid #824AC5; }

.border_accent {
  border: 1px solid #824AC5; }

.border_t_primary {
  border-top: 1px solid #0085DE; }

.border_r_primary {
  border-right: 1px solid #0085DE; }

.border_b_primary {
  border-bottom: 1px solid #0085DE; }

.border_l_primary {
  border-left: 1px solid #0085DE; }

.border_primary {
  border: 1px solid #0085DE; }

.border_t_negative {
  border-top: 1px solid #ED6347; }

.border_r_negative {
  border-right: 1px solid #ED6347; }

.border_b_negative {
  border-bottom: 1px solid #ED6347; }

.border_l_negative {
  border-left: 1px solid #ED6347; }

.border_negative {
  border: 1px solid #ED6347; }

.border_t_positive {
  border-top: 1px solid #79A843; }

.border_r_positive {
  border-right: 1px solid #79A843; }

.border_b_positive {
  border-bottom: 1px solid #79A843; }

.border_l_positive {
  border-left: 1px solid #79A843; }

.border_positive {
  border: 1px solid #79A843; }

.border_t_secondary {
  border-top: 1px solid #E0AF25; }

.border_r_secondary {
  border-right: 1px solid #E0AF25; }

.border_b_secondary {
  border-bottom: 1px solid #E0AF25; }

.border_l_secondary {
  border-left: 1px solid #E0AF25; }

.border_secondary {
  border: 1px solid #E0AF25; }

.border_t_warning {
  border-top: 1px solid #ef9929; }

.border_r_warning {
  border-right: 1px solid #ef9929; }

.border_b_warning {
  border-bottom: 1px solid #ef9929; }

.border_l_warning {
  border-left: 1px solid #ef9929; }

.border_warning {
  border: 1px solid #ef9929; }

.border_t_black {
  border-top: 1px solid #212B36; }

.border_r_black {
  border-right: 1px solid #212B36; }

.border_b_black {
  border-bottom: 1px solid #212B36; }

.border_l_black {
  border-left: 1px solid #212B36; }

.border_black {
  border: 1px solid #212B36; }

.border_t_blue {
  border-top: 1px solid #0085DE; }

.border_r_blue {
  border-right: 1px solid #0085DE; }

.border_b_blue {
  border-bottom: 1px solid #0085DE; }

.border_l_blue {
  border-left: 1px solid #0085DE; }

.border_blue {
  border: 1px solid #0085DE; }

.border_t_brown {
  border-top: 1px solid #82674c; }

.border_r_brown {
  border-right: 1px solid #82674c; }

.border_b_brown {
  border-bottom: 1px solid #82674c; }

.border_l_brown {
  border-left: 1px solid #82674c; }

.border_brown {
  border: 1px solid #82674c; }

.border_t_olive {
  border-top: 1px solid #6A7B56; }

.border_r_olive {
  border-right: 1px solid #6A7B56; }

.border_b_olive {
  border-bottom: 1px solid #6A7B56; }

.border_l_olive {
  border-left: 1px solid #6A7B56; }

.border_olive {
  border: 1px solid #6A7B56; }

.border_t_red {
  border-top: 1px solid #ED6347; }

.border_r_red {
  border-right: 1px solid #ED6347; }

.border_b_red {
  border-bottom: 1px solid #ED6347; }

.border_l_red {
  border-left: 1px solid #ED6347; }

.border_red {
  border: 1px solid #ED6347; }

.border_t_white {
  border-top: 1px solid #FFFFFF; }

.border_r_white {
  border-right: 1px solid #FFFFFF; }

.border_b_white {
  border-bottom: 1px solid #FFFFFF; }

.border_l_white {
  border-left: 1px solid #FFFFFF; }

.border_white, .StepCheckpoint .label, .StepCheckpoint.active .label {
  border: 1px solid #FFFFFF; }

.border_t_yellow {
  border-top: 1px solid #E0AF25; }

.border_r_yellow {
  border-right: 1px solid #E0AF25; }

.border_b_yellow {
  border-bottom: 1px solid #E0AF25; }

.border_l_yellow {
  border-left: 1px solid #E0AF25; }

.border_yellow {
  border: 1px solid #E0AF25; }

.border_t_gold {
  border-top: 1px solid #AF9128; }

.border_r_gold {
  border-right: 1px solid #AF9128; }

.border_b_gold {
  border-bottom: 1px solid #AF9128; }

.border_l_gold {
  border-left: 1px solid #AF9128; }

.border_gold {
  border: 1px solid #AF9128; }

.border_t_green {
  border-top: 1px solid #79A843; }

.border_r_green {
  border-right: 1px solid #79A843; }

.border_b_green {
  border-bottom: 1px solid #79A843; }

.border_l_green {
  border-left: 1px solid #79A843; }

.border_green, .FrameItem.is_active {
  border: 1px solid #79A843; }

.border_t_orange {
  border-top: 1px solid #ef9929; }

.border_r_orange {
  border-right: 1px solid #ef9929; }

.border_b_orange {
  border-bottom: 1px solid #ef9929; }

.border_l_orange {
  border-left: 1px solid #ef9929; }

.border_orange {
  border: 1px solid #ef9929; }

.border_t_pink {
  border-top: 1px solid #E9CACB; }

.border_r_pink {
  border-right: 1px solid #E9CACB; }

.border_b_pink {
  border-bottom: 1px solid #E9CACB; }

.border_l_pink {
  border-left: 1px solid #E9CACB; }

.border_pink {
  border: 1px solid #E9CACB; }

.border_t_purple {
  border-top: 1px solid #824AC5; }

.border_r_purple {
  border-right: 1px solid #824AC5; }

.border_b_purple {
  border-bottom: 1px solid #824AC5; }

.border_l_purple {
  border-left: 1px solid #824AC5; }

.border_purple {
  border: 1px solid #824AC5; }

.border_t_teal {
  border-top: 1px solid #008571; }

.border_r_teal {
  border-right: 1px solid #008571; }

.border_b_teal {
  border-bottom: 1px solid #008571; }

.border_l_teal {
  border-left: 1px solid #008571; }

.border_teal {
  border: 1px solid #008571; }

.border_t_violet {
  border-top: 1px solid #CF2E95; }

.border_r_violet {
  border-right: 1px solid #CF2E95; }

.border_b_violet {
  border-bottom: 1px solid #CF2E95; }

.border_l_violet {
  border-left: 1px solid #CF2E95; }

.border_violet {
  border: 1px solid #CF2E95; }

.border_t_grey {
  border-top: 1px solid #636466; }

.border_r_grey {
  border-right: 1px solid #636466; }

.border_b_grey {
  border-bottom: 1px solid #636466; }

.border_l_grey {
  border-left: 1px solid #636466; }

.border_grey {
  border: 1px solid #636466; }

.border_t_lightest_grey, .Sidebar {
  border-top: 1px solid #eff0f0; }

.border_r_lightest_grey {
  border-right: 1px solid #eff0f0; }

.border_b_lightest_grey {
  border-bottom: 1px solid #eff0f0; }

.border_l_lightest_grey {
  border-left: 1px solid #eff0f0; }

.border_lightest_grey {
  border: 1px solid #eff0f0; }

.border_t_lighter_grey {
  border-top: 1px solid #d0d1d1; }

.border_r_lighter_grey {
  border-right: 1px solid #d0d1d1; }

.border_b_lighter_grey {
  border-bottom: 1px solid #d0d1d1; }

.border_l_lighter_grey {
  border-left: 1px solid #d0d1d1; }

.border_lighter_grey {
  border: 1px solid #d0d1d1; }

.border_t_light_grey {
  border-top: 1px solid #a1a2a3; }

.border_r_light_grey {
  border-right: 1px solid #a1a2a3; }

.border_b_light_grey {
  border-bottom: 1px solid #a1a2a3; }

.border_l_light_grey {
  border-left: 1px solid #a1a2a3; }

.border_light_grey {
  border: 1px solid #a1a2a3; }

.border_t_dark_grey {
  border-top: 1px solid #4a4b4d; }

.border_r_dark_grey {
  border-right: 1px solid #4a4b4d; }

.border_b_dark_grey {
  border-bottom: 1px solid #4a4b4d; }

.border_l_dark_grey {
  border-left: 1px solid #4a4b4d; }

.border_dark_grey {
  border: 1px solid #4a4b4d; }

.border_t_darker_grey {
  border-top: 1px solid #323233; }

.border_r_darker_grey {
  border-right: 1px solid #323233; }

.border_b_darker_grey {
  border-bottom: 1px solid #323233; }

.border_l_darker_grey {
  border-left: 1px solid #323233; }

.border_darker_grey {
  border: 1px solid #323233; }

.border_t_darkest_grey {
  border-top: 1px solid #1e1e1f; }

.border_r_darkest_grey {
  border-right: 1px solid #1e1e1f; }

.border_b_darkest_grey {
  border-bottom: 1px solid #1e1e1f; }

.border_l_darkest_grey {
  border-left: 1px solid #1e1e1f; }

.border_darkest_grey {
  border: 1px solid #1e1e1f; }

.border_none, .StepButton, .StepCheckpoint {
  border: 0; }

.border_radius_50, .status_dot, .StepCheckpoint .label {
  border-radius: 50%; }

.border_rounded, .swatch, .FrameItem, .LibraryImage__checkbox {
  border-radius: 8px; }

.border_dashed {
  border-width: 1px;
  border-style: dashed; }

.border_outlined:before, .outlined_button:before {
  content: "";
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  border: 4px solid white;
  pointer-events: none; }

.color_accent {
  color: #824AC5; }

.on_hover_accent:hover {
  color: #824AC5; }

.color_primary, .Steps a {
  color: #0085DE; }

.on_hover_primary:hover {
  color: #0085DE; }

.color_negative {
  color: #ED6347; }

.on_hover_negative:hover {
  color: #ED6347; }

.color_positive {
  color: #79A843; }

.on_hover_positive:hover {
  color: #79A843; }

.color_secondary {
  color: #E0AF25; }

.on_hover_secondary:hover {
  color: #E0AF25; }

.color_warning {
  color: #ef9929; }

.on_hover_warning:hover {
  color: #ef9929; }

.color_black {
  color: #212B36; }

.on_hover_black:hover {
  color: #212B36; }

.color_blue, .LibraryImage.selected .LibraryImage__checkbox, .StepCheckpoint.active .label {
  color: #0085DE; }

.on_hover_blue:hover {
  color: #0085DE; }

.color_brown {
  color: #82674c; }

.on_hover_brown:hover {
  color: #82674c; }

.color_olive {
  color: #6A7B56; }

.on_hover_olive:hover {
  color: #6A7B56; }

.color_red {
  color: #ED6347; }

.on_hover_red:hover {
  color: #ED6347; }

.color_white, .StepCheckpoint {
  color: #FFFFFF; }

.on_hover_white:hover {
  color: #FFFFFF; }

.color_yellow {
  color: #E0AF25; }

.on_hover_yellow:hover {
  color: #E0AF25; }

.color_gold {
  color: #AF9128; }

.on_hover_gold:hover {
  color: #AF9128; }

.color_green {
  color: #79A843; }

.on_hover_green:hover {
  color: #79A843; }

.color_orange {
  color: #ef9929; }

.on_hover_orange:hover {
  color: #ef9929; }

.color_pink {
  color: #E9CACB; }

.on_hover_pink:hover {
  color: #E9CACB; }

.color_purple {
  color: #824AC5; }

.on_hover_purple:hover {
  color: #824AC5; }

.color_teal {
  color: #008571; }

.on_hover_teal:hover {
  color: #008571; }

.color_violet {
  color: #CF2E95; }

.on_hover_violet:hover {
  color: #CF2E95; }

.color_grey {
  color: #636466; }

.on_hover_grey:hover {
  color: #636466; }

.color_lightest_grey {
  color: #eff0f0; }

.on_hover_lightest_grey:hover {
  color: #eff0f0; }

.color_lighter_grey {
  color: #d0d1d1; }

.on_hover_lighter_grey:hover {
  color: #d0d1d1; }

.color_light_grey {
  color: #a1a2a3; }

.on_hover_light_grey:hover {
  color: #a1a2a3; }

.color_dark_grey {
  color: #4a4b4d; }

.on_hover_dark_grey:hover {
  color: #4a4b4d; }

.color_darker_grey {
  color: #323233; }

.on_hover_darker_grey:hover {
  color: #323233; }

.color_darkest_grey {
  color: #1e1e1f; }

.on_hover_darkest_grey:hover {
  color: #1e1e1f; }

.color_inherit {
  color: inherit; }

.cursor_pointer, .raise_on_hover, .swatch, .slide_right_on_hover, .FrameItem, .StepButton {
  cursor: pointer; }

.pointer_events_none {
  pointer-events: none; }

.display_block, .short_divider, .wysiwyg img, .FrameItem, .FrameItemImage, .Section__content, .Steps a {
  display: block; }

.display_none {
  display: none !important; }

.display_inline_block, .dotted_divider > *, .status_dot {
  display: inline-block !important; }

.display_inline {
  display: inline !important; }

.display_table {
  display: table !important; }

.display_table_row {
  display: table-row !important; }

.display_table_cell {
  display: table-cell !important; }

.display_hidden {
  display: none !important; }

.display_flex, .Editor, .FrameList, .LibraryImage__checkbox, .Masthead, .MatPaletteHeader, .Sidebar, .Sidebar__navigation, .Section__taskbar, .Steps, .StepButton, .StepCheckpoint, .StepCheckpoint .label, .WallcoreLogo {
  display: flex !important; }

.display_inline_flex {
  display: inline-flex !important; }

.transition_all, .raise_on_hover, .swatch, .slide_right_on_hover, .FrameItem, .FrameItemImage, .FramePreviewToggle, .LibraryImage__checkbox, .MatItem, .SectionLabel, .Section__taskbar, .StepButton, .StepButton:after {
  transition: all 150ms ease-out; }

.transition_transform {
  transition: transform 300ms ease-out; }

.transition_all_slow {
  transition: all 500ms ease-out; }

.fade_up_slight {
  animation: fadeUpSlight 250ms ease-out; }

.fade_up {
  animation: fadeUp 250ms ease-out; }

.fade_down_slight {
  animation: fadeDownSlight 250ms ease-out; }

.fade_down {
  animation: fadeDown 250ms ease-out; }

.fade_left, .MatColorButton {
  animation: fadeLeft 250ms ease-out; }

.fade_left_slight {
  animation: fadeLeftSlight 250ms ease-out; }

.fade_right {
  animation: fadeRight 250ms ease-out; }

.fade_right_slight {
  animation: fadeRightSlight 250ms ease-out; }

.duration_xshort {
  animation-duration: 100ms; }

.duration_short {
  animation-duration: 250ms; }

.duration_medium {
  animation-duration: 450ms; }

.duration_long {
  animation-duration: 650ms; }

.duration_xlong {
  animation-duration: 1000ms; }

.rotate_360 {
  transform: rotate(360deg); }

.hover_reveal {
  display: none !important; }

.hover_revealer:hover .hover_reveal {
  display: initial !important; }

.hover_raise {
  position: relative; }
  .hover_raise:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 250ms ease-out; }
  .hover_raise:hover:after {
    opacity: 1; }

.anim_delay_short {
  animation-delay: 50ms; }

.anim_delay_medium {
  animation-delay: 100ms; }

.anim_delay_long {
  animation-delay: 150ms; }

.anim_delay_xlong {
  animation-delay: 200ms; }

.animate_pulse, .animate_pulse_once {
  position: relative; }
  .animate_pulse:after, .animate_pulse_once:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    animation: pulse 2s infinite; }

.animate_pulse_once:after {
  animation: pulse 1s; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0;
    opacity: 0.4; }
  70% {
    box-shadow: 0 0 0 15px;
    opacity: 0; }
  100% {
    box-shadow: 0 0 0 0;
    opacity: 0; } }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeUpSlight {
  0% {
    opacity: 0;
    transform: translate3d(0, 15%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeDownSlight {
  0% {
    opacity: 0;
    transform: translate3d(0, -15%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translate3d(50%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeLeftSlight {
  0% {
    opacity: 0;
    transform: translate3d(15%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeRightSlight {
  0% {
    opacity: 0;
    transform: translate3d(-15%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.flex_grow_1, .Editor, .Section, .Section__content, .Workspace {
  flex-grow: 1; }

.flex_grow_0 {
  flex-grow: 0; }

.flex_shrink_1 {
  flex-shrink: 1; }

.flex_shrink_0, .Editor, .FrameItem, .Masthead, .Sidebar__navigation, .Steps {
  flex-shrink: 0; }

.align_items_center, .LibraryImage__checkbox, .Masthead, .MatPaletteHeader, .Section__taskbar, .StepButton, .StepCheckpoint, .StepCheckpoint .label, .WallcoreLogo {
  align-items: center; }

.align_items_start {
  align-items: flex-start; }

.align_items_end {
  align-items: flex-end; }

.align_content_center {
  align-content: center; }

.justify_content_start {
  justify-content: flex-start; }

.justify_content_end, .StepButton {
  justify-content: flex-end; }

.justify_content_center, .LibraryImage__checkbox, .StepCheckpoint .label {
  justify-content: center; }

.justify_content_right {
  justify-content: right; }

.justify_content_space_between, .MatPaletteHeader {
  justify-content: space-between; }

.flex_wrap {
  flex-wrap: wrap; }

.flex_column, .Editor, .MatPaletteHeader, .Sidebar, .StepButton, .StepCheckpoint {
  flex-direction: column; }

.flex_20 {
  flex: 20%; }

.flex_25 {
  flex: 25%; }

.flex_30 {
  flex: 30%; }

.flex_33 {
  flex: 33.333%; }

.flex_40 {
  flex: 40%; }

.flex_50 {
  flex: 50%; }

.flex_60 {
  flex: 60%; }

.flex_66 {
  flex: 66.666%; }

.flex_70 {
  flex: 70%; }

.flex_75 {
  flex: 75%; }

.flex_80 {
  flex: 80%; }

.flex_100 {
  flex: 100%; }

a.color_accent {
  color: #824AC5; }

a.color_accent:hover,
a.color_accent:focus {
  color: #6935a7; }

a.color_primary, .Steps a {
  color: #0085DE; }

a.color_primary:hover, .Steps a:hover,
a.color_primary:focus,
.Steps a:focus {
  color: #0066ab; }

a.color_negative {
  color: #ED6347; }

a.color_negative:hover,
a.color_negative:focus {
  color: #e83c19; }

a.color_positive {
  color: #79A843; }

a.color_positive:hover,
a.color_positive:focus {
  color: #5f8434; }

a.color_secondary {
  color: #E0AF25; }

a.color_secondary:hover,
a.color_secondary:focus {
  color: #b88f1a; }

a.color_warning {
  color: #ef9929; }

a.color_warning:hover,
a.color_warning:focus {
  color: #d57f10; }

a.color_black {
  color: #212B36; }

a.color_black:hover,
a.color_black:focus {
  color: #0e1216; }

a.color_blue, .LibraryImage.selected a.LibraryImage__checkbox, .StepCheckpoint.active a.label {
  color: #0085DE; }

a.color_blue:hover, .LibraryImage.selected a.LibraryImage__checkbox:hover, .StepCheckpoint.active a.label:hover,
a.color_blue:focus,
.LibraryImage.selected a.LibraryImage__checkbox:focus,
.StepCheckpoint.active a.label:focus {
  color: #0066ab; }

a.color_brown {
  color: #82674c; }

a.color_brown:hover,
a.color_brown:focus {
  color: #624e39; }

a.color_olive {
  color: #6A7B56; }

a.color_olive:hover,
a.color_olive:focus {
  color: #505d41; }

a.color_red {
  color: #ED6347; }

a.color_red:hover,
a.color_red:focus {
  color: #e83c19; }

a.color_white, a.StepCheckpoint {
  color: #FFFFFF; }

a.color_white:hover, a.StepCheckpoint:hover,
a.color_white:focus,
a.StepCheckpoint:focus {
  color: #e6e6e6; }

a.color_yellow {
  color: #E0AF25; }

a.color_yellow:hover,
a.color_yellow:focus {
  color: #b88f1a; }

a.color_gold {
  color: #AF9128; }

a.color_gold:hover,
a.color_gold:focus {
  color: #856f1f; }

a.color_green {
  color: #79A843; }

a.color_green:hover,
a.color_green:focus {
  color: #5f8434; }

a.color_orange {
  color: #ef9929; }

a.color_orange:hover,
a.color_orange:focus {
  color: #d57f10; }

a.color_pink {
  color: #E9CACB; }

a.color_pink:hover,
a.color_pink:focus {
  color: #daa6a8; }

a.color_purple {
  color: #824AC5; }

a.color_purple:hover,
a.color_purple:focus {
  color: #6935a7; }

a.color_teal {
  color: #008571; }

a.color_teal:hover,
a.color_teal:focus {
  color: #005246; }

a.color_violet {
  color: #CF2E95; }

a.color_violet:hover,
a.color_violet:focus {
  color: #a52577; }

a.color_grey {
  color: #636466; }

a.color_grey:hover,
a.color_grey:focus {
  color: #4a4b4c; }

a.color_lightest_grey {
  color: #eff0f0; }

a.color_lightest_grey:hover,
a.color_lightest_grey:focus {
  color: #d5d7d7; }

a.color_lighter_grey {
  color: #d0d1d1; }

a.color_lighter_grey:hover,
a.color_lighter_grey:focus {
  color: #b6b8b8; }

a.color_light_grey {
  color: #a1a2a3; }

a.color_light_grey:hover,
a.color_light_grey:focus {
  color: #87898a; }

a.color_dark_grey {
  color: #4a4b4d; }

a.color_dark_grey:hover,
a.color_dark_grey:focus {
  color: #313233; }

a.color_darker_grey {
  color: #323233; }

a.color_darker_grey:hover,
a.color_darker_grey:focus {
  color: #191919; }

a.color_darkest_grey {
  color: #1e1e1f; }

a.color_darkest_grey:hover,
a.color_darkest_grey:focus {
  color: #050505; }

.pointer:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  transition: all 250ms ease-out;
  color: white; }

.pointer.OnboardStep:after {
  color: #0085DE; }

.pointer.down:after {
  margin-top: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent; }

.pointer.left.top:after {
  margin-right: 0;
  top: 100%;
  left: 20px;
  border-top: 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent; }

.pointer.right.top:after {
  margin-right: 0;
  top: 20px;
  right: 100%;
  border-right: 10px solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; }

.pointer.right.center:after {
  margin-right: 0;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-right: 10px solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; }

.pointer.left.center:after {
  margin-left: 0;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-left: 10px solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; }

.pointer.right.bottom:after {
  margin-bottom: 0;
  bottom: 100%;
  right: 20px;
  border-bottom: 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent; }

.pointer.left.bottom:after {
  margin-bottom: 0;
  bottom: 100%;
  left: 20px;
  border-bottom: 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent; }

.pointer.large:after {
  border-width: 15px !important; }

.position_static {
  position: static; }

.position_absolute, .border_outlined:before, .outlined_button:before, .gallery_card .icon.search, .FramePreviewToggle, .LibraryImage__checkbox, .MatColorButton, .WallcoreLogo {
  position: absolute; }

.position_relative, .border_outlined, .outlined_button, .hero, .home_old .home_hero_inner, .Editor, .FrameItem, .MatItem, .StepContent, .Section__content, .Steps, .StepButton, .Workspace {
  position: relative; }

.position_fixed, .Sidebar__navigation, .Section__taskbar {
  position: fixed; }

.top_0, .WallcoreLogo {
  top: 0%; }

.top_50, .gallery_card .icon.search, .FramePreviewToggle {
  top: 50%; }

.top_100 {
  top: 100%; }

.right_0 {
  right: 0%; }

.right_50 {
  right: 50%; }

.right_100 {
  right: 100%; }

.bottom_0, .Sidebar__navigation {
  bottom: 0%; }

.bottom_50 {
  bottom: 50%; }

.bottom_100 {
  bottom: 100%; }

.left_0, .Sidebar__navigation, .Section__taskbar, .WallcoreLogo {
  left: 0%; }

.left_50, .gallery_card .icon.search, .FramePreviewToggle {
  left: 50%; }

.left_100 {
  left: 100%; }

.transform_center {
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0); }

.transform_center_x {
  left: 50%;
  transform: translate3d(-50%, 0, 0); }

.transform_center_y {
  top: 50%;
  transform: translate3d(0, -50%, 0); }

.translate_left_10 {
  transform: translate3d(-10%, 0, 0); }

.z_index_-1 {
  z-index: -1; }

.z_index_0 {
  z-index: 0; }

.z_index_1, .home_old .home_hero_inner, .StepContent {
  z-index: 1; }

.z_index_100 {
  z-index: 100; }

.z_index_200 {
  z-index: 200; }

.z_index_300 {
  z-index: 300; }

.z_index_400 {
  z-index: 400; }

.z_index_500 {
  z-index: 500; }

.z_index_600 {
  z-index: 600; }

.z_index_700 {
  z-index: 700; }

.z_index_800 {
  z-index: 800; }

.z_index_900 {
  z-index: 900; }

.z_index_1000 {
  z-index: 1000; }

.z_index_2000 {
  z-index: 2000; }

.z_index_3000 {
  z-index: 3000; }

.z_index_top {
  z-index: 9999; }

.float_left {
  float: left; }

.float_right {
  float: right; }

.clearfix:before, .hero:before, .masonry:before, .clearfix:after, .hero:after, .masonry:after {
  content: "";
  display: table; }

.clearfix:after, .hero:after, .masonry:after {
  clear: both; }

.show_mobile,
.only_mobile {
  display: none !important; }

@media only screen and (min-width: 0) {
  .show_mobile,
  .only_mobile {
    display: initial !important; }
    .show_mobile.display_flex, .show_mobile.Editor, .show_mobile.FrameList, .show_mobile.LibraryImage__checkbox, .show_mobile.Masthead, .show_mobile.MatPaletteHeader, .show_mobile.Sidebar, .show_mobile.Sidebar__navigation, .show_mobile.Section__taskbar, .show_mobile.Steps, .show_mobile.StepButton, .show_mobile.StepCheckpoint, .StepCheckpoint .show_mobile.label, .show_mobile.WallcoreLogo,
    .only_mobile.display_flex,
    .only_mobile.Editor,
    .only_mobile.FrameList,
    .only_mobile.LibraryImage__checkbox,
    .only_mobile.Masthead,
    .only_mobile.MatPaletteHeader,
    .only_mobile.Sidebar,
    .only_mobile.Sidebar__navigation,
    .only_mobile.Section__taskbar,
    .only_mobile.Steps,
    .only_mobile.StepButton,
    .only_mobile.StepCheckpoint,
    .StepCheckpoint .only_mobile.label,
    .only_mobile.WallcoreLogo {
      display: flex !important; }
    .show_mobile.display_inline_flex,
    .only_mobile.display_inline_flex {
      display: inline-flex !important; }
    .show_mobile.display_inline,
    .only_mobile.display_inline {
      display: inline !important; }
    .show_mobile.display_inline_block, .dotted_divider > .show_mobile, .show_mobile.status_dot,
    .only_mobile.display_inline_block,
    .dotted_divider > .only_mobile,
    .only_mobile.status_dot {
      display: inline-block !important; }
  .hide_mobile {
    display: none !important; } }

@media only screen and (min-width: 767px) {
  .only_mobile {
    display: none !important; } }

.show_tablet,
.only_tablet {
  display: none !important; }

@media only screen and (min-width: 768px) {
  .show_tablet,
  .only_tablet {
    display: initial !important; }
    .show_tablet.display_flex, .show_tablet.Editor, .show_tablet.FrameList, .show_tablet.LibraryImage__checkbox, .show_tablet.Masthead, .show_tablet.MatPaletteHeader, .show_tablet.Sidebar, .show_tablet.Sidebar__navigation, .show_tablet.Section__taskbar, .show_tablet.Steps, .show_tablet.StepButton, .show_tablet.StepCheckpoint, .StepCheckpoint .show_tablet.label, .show_tablet.WallcoreLogo,
    .only_tablet.display_flex,
    .only_tablet.Editor,
    .only_tablet.FrameList,
    .only_tablet.LibraryImage__checkbox,
    .only_tablet.Masthead,
    .only_tablet.MatPaletteHeader,
    .only_tablet.Sidebar,
    .only_tablet.Sidebar__navigation,
    .only_tablet.Section__taskbar,
    .only_tablet.Steps,
    .only_tablet.StepButton,
    .only_tablet.StepCheckpoint,
    .StepCheckpoint .only_tablet.label,
    .only_tablet.WallcoreLogo {
      display: flex !important; }
    .show_tablet.display_inline_flex,
    .only_tablet.display_inline_flex {
      display: inline-flex !important; }
    .show_tablet.display_inline,
    .only_tablet.display_inline {
      display: inline !important; }
    .show_tablet.display_inline_block, .dotted_divider > .show_tablet, .show_tablet.status_dot,
    .only_tablet.display_inline_block,
    .dotted_divider > .only_tablet,
    .only_tablet.status_dot {
      display: inline-block !important; }
  .hide_tablet {
    display: none !important; } }

@media only screen and (min-width: 999px) {
  .only_tablet {
    display: none !important; } }

.show_computer,
.only_computer {
  display: none !important; }

@media only screen and (min-width: 1000px) {
  .show_computer,
  .only_computer {
    display: initial !important; }
    .show_computer.display_flex, .show_computer.Editor, .show_computer.FrameList, .show_computer.LibraryImage__checkbox, .show_computer.Masthead, .show_computer.MatPaletteHeader, .show_computer.Sidebar, .show_computer.Sidebar__navigation, .show_computer.Section__taskbar, .show_computer.Steps, .show_computer.StepButton, .show_computer.StepCheckpoint, .StepCheckpoint .show_computer.label, .show_computer.WallcoreLogo,
    .only_computer.display_flex,
    .only_computer.Editor,
    .only_computer.FrameList,
    .only_computer.LibraryImage__checkbox,
    .only_computer.Masthead,
    .only_computer.MatPaletteHeader,
    .only_computer.Sidebar,
    .only_computer.Sidebar__navigation,
    .only_computer.Section__taskbar,
    .only_computer.Steps,
    .only_computer.StepButton,
    .only_computer.StepCheckpoint,
    .StepCheckpoint .only_computer.label,
    .only_computer.WallcoreLogo {
      display: flex !important; }
    .show_computer.display_inline_flex,
    .only_computer.display_inline_flex {
      display: inline-flex !important; }
    .show_computer.display_inline,
    .only_computer.display_inline {
      display: inline !important; }
    .show_computer.display_inline_block, .dotted_divider > .show_computer, .show_computer.status_dot,
    .only_computer.display_inline_block,
    .dotted_divider > .only_computer,
    .only_computer.status_dot {
      display: inline-block !important; }
  .hide_computer {
    display: none !important; } }

@media only screen and (min-width: 1199px) {
  .only_computer {
    display: none !important; } }

.show_large_screen,
.only_large_screen {
  display: none !important; }

@media only screen and (min-width: 1200px) {
  .show_large_screen,
  .only_large_screen {
    display: initial !important; }
    .show_large_screen.display_flex, .show_large_screen.Editor, .show_large_screen.FrameList, .show_large_screen.LibraryImage__checkbox, .show_large_screen.Masthead, .show_large_screen.MatPaletteHeader, .show_large_screen.Sidebar, .show_large_screen.Sidebar__navigation, .show_large_screen.Section__taskbar, .show_large_screen.Steps, .show_large_screen.StepButton, .show_large_screen.StepCheckpoint, .StepCheckpoint .show_large_screen.label, .show_large_screen.WallcoreLogo,
    .only_large_screen.display_flex,
    .only_large_screen.Editor,
    .only_large_screen.FrameList,
    .only_large_screen.LibraryImage__checkbox,
    .only_large_screen.Masthead,
    .only_large_screen.MatPaletteHeader,
    .only_large_screen.Sidebar,
    .only_large_screen.Sidebar__navigation,
    .only_large_screen.Section__taskbar,
    .only_large_screen.Steps,
    .only_large_screen.StepButton,
    .only_large_screen.StepCheckpoint,
    .StepCheckpoint .only_large_screen.label,
    .only_large_screen.WallcoreLogo {
      display: flex !important; }
    .show_large_screen.display_inline_flex,
    .only_large_screen.display_inline_flex {
      display: inline-flex !important; }
    .show_large_screen.display_inline,
    .only_large_screen.display_inline {
      display: inline !important; }
    .show_large_screen.display_inline_block, .dotted_divider > .show_large_screen, .show_large_screen.status_dot,
    .only_large_screen.display_inline_block,
    .dotted_divider > .only_large_screen,
    .only_large_screen.status_dot {
      display: inline-block !important; }
  .hide_large_screen {
    display: none !important; } }

@media only screen and (min-width: 1499px) {
  .only_large_screen {
    display: none !important; } }

.show_widescreen,
.only_widescreen {
  display: none !important; }

@media only screen and (min-width: 1500px) {
  .show_widescreen,
  .only_widescreen {
    display: initial !important; }
    .show_widescreen.display_flex, .show_widescreen.Editor, .show_widescreen.FrameList, .show_widescreen.LibraryImage__checkbox, .show_widescreen.Masthead, .show_widescreen.MatPaletteHeader, .show_widescreen.Sidebar, .show_widescreen.Sidebar__navigation, .show_widescreen.Section__taskbar, .show_widescreen.Steps, .show_widescreen.StepButton, .show_widescreen.StepCheckpoint, .StepCheckpoint .show_widescreen.label, .show_widescreen.WallcoreLogo,
    .only_widescreen.display_flex,
    .only_widescreen.Editor,
    .only_widescreen.FrameList,
    .only_widescreen.LibraryImage__checkbox,
    .only_widescreen.Masthead,
    .only_widescreen.MatPaletteHeader,
    .only_widescreen.Sidebar,
    .only_widescreen.Sidebar__navigation,
    .only_widescreen.Section__taskbar,
    .only_widescreen.Steps,
    .only_widescreen.StepButton,
    .only_widescreen.StepCheckpoint,
    .StepCheckpoint .only_widescreen.label,
    .only_widescreen.WallcoreLogo {
      display: flex !important; }
    .show_widescreen.display_inline_flex,
    .only_widescreen.display_inline_flex {
      display: inline-flex !important; }
    .show_widescreen.display_inline,
    .only_widescreen.display_inline {
      display: inline !important; }
    .show_widescreen.display_inline_block, .dotted_divider > .show_widescreen, .show_widescreen.status_dot,
    .only_widescreen.display_inline_block,
    .dotted_divider > .only_widescreen,
    .only_widescreen.status_dot {
      display: inline-block !important; }
  .hide_widescreen {
    display: none !important; } }

@media only screen and (min-width: 9999px) {
  .only_widescreen {
    display: none !important; } }

.box_shadow, .FrameItem.is_active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1); }

.box_shadow_strong, .hover_raise:after {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1), 0 32px 64px rgba(0, 0, 0, 0.1); }

.box_shadow_upper, .Section__taskbar {
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.3); }

.box_shadow_light {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }

.box_shadow_upper_light {
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2); }

.width_auto, .wysiwyg img, .FrameItemImage, .StepButton {
  width: auto; }

.height_auto, .wysiwyg img, .FrameItemImage {
  height: auto; }

.max_width_100, .wysiwyg img {
  max-width: 100%; }

.max_height_100 {
  max-height: 100%; }

.max_width_mobile {
  max-width: 0; }

.max_width_tablet {
  max-width: 768px; }

.max_width_computer {
  max-width: 1000px; }

.max_width_large_screen {
  max-width: 1200px; }

.max_width_widescreen {
  max-width: 1500px; }

.width_20 {
  width: 20%; }

.width_25 {
  width: 25%; }

.width_30 {
  width: 30%; }

.width_33 {
  width: 33.333%; }

.width_40 {
  width: 40%; }

.width_50 {
  width: 50%; }

.width_60 {
  width: 60%; }

.width_66 {
  width: 66.666%; }

.width_70 {
  width: 70%; }

.width_75 {
  width: 75%; }

.width_80 {
  width: 80%; }

.width_100, .Sidebar__navigation, .Section, .Section__taskbar, .WallcoreLogo {
  width: 100%; }

.max_width_100, .wysiwyg img {
  max-width: 100%; }

@media only screen and (min-width: 0) {
  .width_20_mobile {
    width: 20%; }
  .width_25_mobile {
    width: 25%; }
  .width_30_mobile {
    width: 30%; }
  .width_33_mobile {
    width: 33.333%; }
  .width_40_mobile {
    width: 40%; }
  .width_50_mobile {
    width: 50%; }
  .width_60_mobile {
    width: 60%; }
  .width_66_mobile {
    width: 66.666%; }
  .width_70_mobile {
    width: 70%; }
  .width_75_mobile {
    width: 75%; }
  .width_80_mobile {
    width: 80%; }
  .width_100_mobile {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .width_20_tablet {
    width: 20%; }
  .width_25_tablet {
    width: 25%; }
  .width_30_tablet {
    width: 30%; }
  .width_33_tablet {
    width: 33.333%; }
  .width_40_tablet {
    width: 40%; }
  .width_50_tablet {
    width: 50%; }
  .width_60_tablet {
    width: 60%; }
  .width_66_tablet {
    width: 66.666%; }
  .width_70_tablet {
    width: 70%; }
  .width_75_tablet {
    width: 75%; }
  .width_80_tablet {
    width: 80%; }
  .width_100_tablet {
    width: 100%; } }

@media only screen and (min-width: 1000px) {
  .width_20_computer {
    width: 20%; }
  .width_25_computer {
    width: 25%; }
  .width_30_computer {
    width: 30%; }
  .width_33_computer {
    width: 33.333%; }
  .width_40_computer {
    width: 40%; }
  .width_50_computer {
    width: 50%; }
  .width_60_computer {
    width: 60%; }
  .width_66_computer {
    width: 66.666%; }
  .width_70_computer {
    width: 70%; }
  .width_75_computer {
    width: 75%; }
  .width_80_computer {
    width: 80%; }
  .width_100_computer {
    width: 100%; } }

@media only screen and (min-width: 1200px) {
  .width_20_large_screen {
    width: 20%; }
  .width_25_large_screen {
    width: 25%; }
  .width_30_large_screen {
    width: 30%; }
  .width_33_large_screen {
    width: 33.333%; }
  .width_40_large_screen {
    width: 40%; }
  .width_50_large_screen {
    width: 50%; }
  .width_60_large_screen {
    width: 60%; }
  .width_66_large_screen {
    width: 66.666%; }
  .width_70_large_screen {
    width: 70%; }
  .width_75_large_screen {
    width: 75%; }
  .width_80_large_screen {
    width: 80%; }
  .width_100_large_screen {
    width: 100%; } }

@media only screen and (min-width: 1500px) {
  .width_20_widescreen {
    width: 20%; }
  .width_25_widescreen {
    width: 25%; }
  .width_30_widescreen {
    width: 30%; }
  .width_33_widescreen {
    width: 33.333%; }
  .width_40_widescreen {
    width: 40%; }
  .width_50_widescreen {
    width: 50%; }
  .width_60_widescreen {
    width: 60%; }
  .width_66_widescreen {
    width: 66.666%; }
  .width_70_widescreen {
    width: 70%; }
  .width_75_widescreen {
    width: 75%; }
  .width_80_widescreen {
    width: 80%; }
  .width_100_widescreen {
    width: 100%; } }

.height_100, .WallcoreLogo {
  height: 100%; }

.max_height_100 {
  max-height: 100%; }

.height_auto, .wysiwyg img, .FrameItemImage {
  height: auto; }

.pt_16x9 {
  padding-top: 56%; }

.ma_auto {
  margin: auto !important; }

.mt_auto {
  margin-top: auto !important; }

.mb_auto {
  margin-bottom: auto !important; }

.mr_auto {
  margin-right: auto !important; }

.ml_auto {
  margin-left: auto !important; }

.mx_auto, .short_divider.centered {
  margin-left: auto !important;
  margin-right: auto !important; }

.my_auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.ma_nudge {
  margin: 0.5rem !important; }

.pa_nudge {
  padding: 0.5rem !important; }

.mx_nudge {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important; }

.px_nudge {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.my_nudge {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.py_nudge {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.mt_nudge {
  margin-top: 0.5rem !important; }

.pt_nudge {
  padding-top: 0.5rem !important; }

.mr_nudge {
  margin-right: 0.5rem !important; }

.pr_nudge {
  padding-right: 0.5rem !important; }

.mb_nudge, .StepCheckpoint .label {
  margin-bottom: 0.5rem !important; }

.pb_nudge {
  padding-bottom: 0.5rem !important; }

.ml_nudge {
  margin-left: 0.5rem !important; }

.pl_nudge {
  padding-left: 0.5rem !important; }

.ma_half {
  margin: 0.75rem !important; }

.pa_half {
  padding: 0.75rem !important; }

.mx_half {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important; }

.px_half {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important; }

.my_half {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.py_half, .Section__taskbar {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.mt_half {
  margin-top: 0.75rem !important; }

.pt_half {
  padding-top: 0.75rem !important; }

.mr_half {
  margin-right: 0.75rem !important; }

.pr_half {
  padding-right: 0.75rem !important; }

.mb_half {
  margin-bottom: 0.75rem !important; }

.pb_half {
  padding-bottom: 0.75rem !important; }

.ml_half {
  margin-left: 0.75rem !important; }

.pl_half {
  padding-left: 0.75rem !important; }

.ma_0 {
  margin: 0 !important; }

.pa_0 {
  padding: 0 !important; }

.mx_0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.px_0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.my_0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.py_0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.mt_0 {
  margin-top: 0 !important; }

.pt_0 {
  padding-top: 0 !important; }

.mr_0 {
  margin-right: 0 !important; }

.pr_0 {
  padding-right: 0 !important; }

.mb_0 {
  margin-bottom: 0 !important; }

.pb_0 {
  padding-bottom: 0 !important; }

.ml_0 {
  margin-left: 0 !important; }

.pl_0 {
  padding-left: 0 !important; }

.ma_1 {
  margin: 1.5rem !important; }

.pa_1 {
  padding: 1.5rem !important; }

.mx_1 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.px_1, .home_old .home_hero_inner {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.my_1 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.py_1 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.mt_1 {
  margin-top: 1.5rem !important; }

.pt_1 {
  padding-top: 1.5rem !important; }

.mr_1 {
  margin-right: 1.5rem !important; }

.pr_1, .dotted_divider > * {
  padding-right: 1.5rem !important; }

.mb_1, .wysiwyg img {
  margin-bottom: 1.5rem !important; }

.pb_1 {
  padding-bottom: 1.5rem !important; }

.ml_1 {
  margin-left: 1.5rem !important; }

.pl_1, .dotted_divider > * {
  padding-left: 1.5rem !important; }

.ma_2 {
  margin: 1.5rem !important; }

.pa_2 {
  padding: 1.5rem !important; }

.mx_2 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.px_2 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.my_2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.py_2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.mt_2 {
  margin-top: 1.5rem !important; }

.pt_2 {
  padding-top: 1.5rem !important; }

.mr_2 {
  margin-right: 1.5rem !important; }

.pr_2 {
  padding-right: 1.5rem !important; }

.mb_2 {
  margin-bottom: 1.5rem !important; }

.pb_2 {
  padding-bottom: 1.5rem !important; }

.ml_2 {
  margin-left: 1.5rem !important; }

.pl_2 {
  padding-left: 1.5rem !important; }

@media only screen and (min-width: 1000px) {
  .ma_2 {
    margin: 1.875rem !important; }
  .pa_2 {
    padding: 1.875rem !important; }
  .mx_2 {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important; }
  .px_2 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important; }
  .my_2 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .py_2 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .mt_2 {
    margin-top: 1.875rem !important; }
  .pt_2 {
    padding-top: 1.875rem !important; }
  .mr_2 {
    margin-right: 1.875rem !important; }
  .pr_2 {
    padding-right: 1.875rem !important; }
  .mb_2 {
    margin-bottom: 1.875rem !important; }
  .pb_2 {
    padding-bottom: 1.875rem !important; }
  .ml_2 {
    margin-left: 1.875rem !important; }
  .pl_2 {
    padding-left: 1.875rem !important; } }

.ma_3 {
  margin: 1.5rem !important; }

.pa_3, .Openings {
  padding: 1.5rem !important; }

.mx_3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.px_3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.my_3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.py_3, .home_old .home_hero_inner {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.mt_3 {
  margin-top: 1.5rem !important; }

.pt_3 {
  padding-top: 1.5rem !important; }

.mr_3 {
  margin-right: 1.5rem !important; }

.pr_3 {
  padding-right: 1.5rem !important; }

.mb_3 {
  margin-bottom: 1.5rem !important; }

.pb_3 {
  padding-bottom: 1.5rem !important; }

.ml_3 {
  margin-left: 1.5rem !important; }

.pl_3 {
  padding-left: 1.5rem !important; }

@media only screen and (min-width: 768px) {
  .ma_3 {
    margin: 1.875rem !important; }
  .pa_3, .Openings {
    padding: 1.875rem !important; }
  .mx_3 {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important; }
  .px_3 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important; }
  .my_3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .py_3, .home_old .home_hero_inner {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .mt_3 {
    margin-top: 1.875rem !important; }
  .pt_3 {
    padding-top: 1.875rem !important; }
  .mr_3 {
    margin-right: 1.875rem !important; }
  .pr_3 {
    padding-right: 1.875rem !important; }
  .mb_3 {
    margin-bottom: 1.875rem !important; }
  .pb_3 {
    padding-bottom: 1.875rem !important; }
  .ml_3 {
    margin-left: 1.875rem !important; }
  .pl_3 {
    padding-left: 1.875rem !important; } }

@media only screen and (min-width: 1000px) {
  .ma_3 {
    margin: 2.625rem !important; }
  .pa_3, .Openings {
    padding: 2.625rem !important; }
  .mx_3 {
    margin-left: 2.625rem !important;
    margin-right: 2.625rem !important; }
  .px_3 {
    padding-left: 2.625rem !important;
    padding-right: 2.625rem !important; }
  .my_3 {
    margin-top: 2.625rem !important;
    margin-bottom: 2.625rem !important; }
  .py_3, .home_old .home_hero_inner {
    padding-top: 2.625rem !important;
    padding-bottom: 2.625rem !important; }
  .mt_3 {
    margin-top: 2.625rem !important; }
  .pt_3 {
    padding-top: 2.625rem !important; }
  .mr_3 {
    margin-right: 2.625rem !important; }
  .pr_3 {
    padding-right: 2.625rem !important; }
  .mb_3 {
    margin-bottom: 2.625rem !important; }
  .pb_3 {
    padding-bottom: 2.625rem !important; }
  .ml_3 {
    margin-left: 2.625rem !important; }
  .pl_3 {
    padding-left: 2.625rem !important; } }

.ma_4 {
  margin: 1.875rem !important; }

.pa_4 {
  padding: 1.875rem !important; }

.mx_4 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important; }

.px_4 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important; }

.my_4 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important; }

.py_4 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important; }

.mt_4 {
  margin-top: 1.875rem !important; }

.pt_4 {
  padding-top: 1.875rem !important; }

.mr_4 {
  margin-right: 1.875rem !important; }

.pr_4 {
  padding-right: 1.875rem !important; }

.mb_4 {
  margin-bottom: 1.875rem !important; }

.pb_4 {
  padding-bottom: 1.875rem !important; }

.ml_4 {
  margin-left: 1.875rem !important; }

.pl_4 {
  padding-left: 1.875rem !important; }

@media only screen and (min-width: 768px) {
  .ma_4 {
    margin: 2.625rem !important; }
  .pa_4 {
    padding: 2.625rem !important; }
  .mx_4 {
    margin-left: 2.625rem !important;
    margin-right: 2.625rem !important; }
  .px_4 {
    padding-left: 2.625rem !important;
    padding-right: 2.625rem !important; }
  .my_4 {
    margin-top: 2.625rem !important;
    margin-bottom: 2.625rem !important; }
  .py_4 {
    padding-top: 2.625rem !important;
    padding-bottom: 2.625rem !important; }
  .mt_4 {
    margin-top: 2.625rem !important; }
  .pt_4 {
    padding-top: 2.625rem !important; }
  .mr_4 {
    margin-right: 2.625rem !important; }
  .pr_4 {
    padding-right: 2.625rem !important; }
  .mb_4 {
    margin-bottom: 2.625rem !important; }
  .pb_4 {
    padding-bottom: 2.625rem !important; }
  .ml_4 {
    margin-left: 2.625rem !important; }
  .pl_4 {
    padding-left: 2.625rem !important; } }

@media only screen and (min-width: 1000px) {
  .ma_4 {
    margin: 4.125rem !important; }
  .pa_4 {
    padding: 4.125rem !important; }
  .mx_4 {
    margin-left: 4.125rem !important;
    margin-right: 4.125rem !important; }
  .px_4 {
    padding-left: 4.125rem !important;
    padding-right: 4.125rem !important; }
  .my_4 {
    margin-top: 4.125rem !important;
    margin-bottom: 4.125rem !important; }
  .py_4 {
    padding-top: 4.125rem !important;
    padding-bottom: 4.125rem !important; }
  .mt_4 {
    margin-top: 4.125rem !important; }
  .pt_4 {
    padding-top: 4.125rem !important; }
  .mr_4 {
    margin-right: 4.125rem !important; }
  .pr_4 {
    padding-right: 4.125rem !important; }
  .mb_4 {
    margin-bottom: 4.125rem !important; }
  .pb_4 {
    padding-bottom: 4.125rem !important; }
  .ml_4 {
    margin-left: 4.125rem !important; }
  .pl_4 {
    padding-left: 4.125rem !important; } }

.ma_5 {
  margin: 2.625rem !important; }

.pa_5 {
  padding: 2.625rem !important; }

.mx_5 {
  margin-left: 2.625rem !important;
  margin-right: 2.625rem !important; }

.px_5 {
  padding-left: 2.625rem !important;
  padding-right: 2.625rem !important; }

.my_5 {
  margin-top: 2.625rem !important;
  margin-bottom: 2.625rem !important; }

.py_5 {
  padding-top: 2.625rem !important;
  padding-bottom: 2.625rem !important; }

.mt_5 {
  margin-top: 2.625rem !important; }

.pt_5 {
  padding-top: 2.625rem !important; }

.mr_5 {
  margin-right: 2.625rem !important; }

.pr_5 {
  padding-right: 2.625rem !important; }

.mb_5 {
  margin-bottom: 2.625rem !important; }

.pb_5 {
  padding-bottom: 2.625rem !important; }

.ml_5 {
  margin-left: 2.625rem !important; }

.pl_5 {
  padding-left: 2.625rem !important; }

@media only screen and (min-width: 768px) {
  .ma_5 {
    margin: 4.125rem !important; }
  .pa_5 {
    padding: 4.125rem !important; }
  .mx_5 {
    margin-left: 4.125rem !important;
    margin-right: 4.125rem !important; }
  .px_5 {
    padding-left: 4.125rem !important;
    padding-right: 4.125rem !important; }
  .my_5 {
    margin-top: 4.125rem !important;
    margin-bottom: 4.125rem !important; }
  .py_5 {
    padding-top: 4.125rem !important;
    padding-bottom: 4.125rem !important; }
  .mt_5 {
    margin-top: 4.125rem !important; }
  .pt_5 {
    padding-top: 4.125rem !important; }
  .mr_5 {
    margin-right: 4.125rem !important; }
  .pr_5 {
    padding-right: 4.125rem !important; }
  .mb_5 {
    margin-bottom: 4.125rem !important; }
  .pb_5 {
    padding-bottom: 4.125rem !important; }
  .ml_5 {
    margin-left: 4.125rem !important; }
  .pl_5 {
    padding-left: 4.125rem !important; } }

@media only screen and (min-width: 1000px) {
  .ma_5 {
    margin: 7.125rem !important; }
  .pa_5 {
    padding: 7.125rem !important; }
  .mx_5 {
    margin-left: 7.125rem !important;
    margin-right: 7.125rem !important; }
  .px_5 {
    padding-left: 7.125rem !important;
    padding-right: 7.125rem !important; }
  .my_5 {
    margin-top: 7.125rem !important;
    margin-bottom: 7.125rem !important; }
  .py_5 {
    padding-top: 7.125rem !important;
    padding-bottom: 7.125rem !important; }
  .mt_5 {
    margin-top: 7.125rem !important; }
  .pt_5 {
    padding-top: 7.125rem !important; }
  .mr_5 {
    margin-right: 7.125rem !important; }
  .pr_5 {
    padding-right: 7.125rem !important; }
  .mb_5 {
    margin-bottom: 7.125rem !important; }
  .pb_5 {
    padding-bottom: 7.125rem !important; }
  .ml_5 {
    margin-left: 7.125rem !important; }
  .pl_5 {
    padding-left: 7.125rem !important; } }

.ma_6 {
  margin: 4.125rem !important; }

.pa_6 {
  padding: 4.125rem !important; }

.mx_6 {
  margin-left: 4.125rem !important;
  margin-right: 4.125rem !important; }

.px_6 {
  padding-left: 4.125rem !important;
  padding-right: 4.125rem !important; }

.my_6 {
  margin-top: 4.125rem !important;
  margin-bottom: 4.125rem !important; }

.py_6 {
  padding-top: 4.125rem !important;
  padding-bottom: 4.125rem !important; }

.mt_6 {
  margin-top: 4.125rem !important; }

.pt_6 {
  padding-top: 4.125rem !important; }

.mr_6 {
  margin-right: 4.125rem !important; }

.pr_6 {
  padding-right: 4.125rem !important; }

.mb_6 {
  margin-bottom: 4.125rem !important; }

.pb_6 {
  padding-bottom: 4.125rem !important; }

.ml_6 {
  margin-left: 4.125rem !important; }

.pl_6 {
  padding-left: 4.125rem !important; }

@media only screen and (min-width: 768px) {
  .ma_6 {
    margin: 7.125rem !important; }
  .pa_6 {
    padding: 7.125rem !important; }
  .mx_6 {
    margin-left: 7.125rem !important;
    margin-right: 7.125rem !important; }
  .px_6 {
    padding-left: 7.125rem !important;
    padding-right: 7.125rem !important; }
  .my_6 {
    margin-top: 7.125rem !important;
    margin-bottom: 7.125rem !important; }
  .py_6 {
    padding-top: 7.125rem !important;
    padding-bottom: 7.125rem !important; }
  .mt_6 {
    margin-top: 7.125rem !important; }
  .pt_6 {
    padding-top: 7.125rem !important; }
  .mr_6 {
    margin-right: 7.125rem !important; }
  .pr_6 {
    padding-right: 7.125rem !important; }
  .mb_6 {
    margin-bottom: 7.125rem !important; }
  .pb_6 {
    padding-bottom: 7.125rem !important; }
  .ml_6 {
    margin-left: 7.125rem !important; }
  .pl_6 {
    padding-left: 7.125rem !important; } }

@media only screen and (min-width: 1000px) {
  .ma_6 {
    margin: 13.125rem !important; }
  .pa_6 {
    padding: 13.125rem !important; }
  .mx_6 {
    margin-left: 13.125rem !important;
    margin-right: 13.125rem !important; }
  .px_6 {
    padding-left: 13.125rem !important;
    padding-right: 13.125rem !important; }
  .my_6 {
    margin-top: 13.125rem !important;
    margin-bottom: 13.125rem !important; }
  .py_6 {
    padding-top: 13.125rem !important;
    padding-bottom: 13.125rem !important; }
  .mt_6 {
    margin-top: 13.125rem !important; }
  .pt_6 {
    padding-top: 13.125rem !important; }
  .mr_6 {
    margin-right: 13.125rem !important; }
  .pr_6 {
    padding-right: 13.125rem !important; }
  .mb_6 {
    margin-bottom: 13.125rem !important; }
  .pb_6 {
    padding-bottom: 13.125rem !important; }
  .ml_6 {
    margin-left: 13.125rem !important; }
  .pl_6 {
    padding-left: 13.125rem !important; } }

.text_left, .FrameItem {
  text-align: left; }

.text_center, .dotted_divider, .home_old .home_hero_inner, .Steps a, .StepButton {
  text-align: center; }

.text_right {
  text-align: right; }

@media only screen and (min-width: 0) {
  .text_left_mobile {
    text-align: left; }
  .text_center_mobile {
    text-align: center; }
  .text_right_mobile {
    text-align: right; } }

@media only screen and (min-width: 768px) {
  .text_left_tablet {
    text-align: left; }
  .text_center_tablet {
    text-align: center; }
  .text_right_tablet {
    text-align: right; } }

@media only screen and (min-width: 1000px) {
  .text_left_computer {
    text-align: left; }
  .text_center_computer {
    text-align: center; }
  .text_right_computer {
    text-align: right; } }

@media only screen and (min-width: 1200px) {
  .text_left_large_screen {
    text-align: left; }
  .text_center_large_screen {
    text-align: center; }
  .text_right_large_screen {
    text-align: right; } }

@media only screen and (min-width: 1500px) {
  .text_left_widescreen {
    text-align: left; }
  .text_center_widescreen {
    text-align: center; }
  .text_right_widescreen {
    text-align: right; } }

.weight_bold, .Steps a, .StepButton, .StepCheckpoint {
  font-weight: 700; }

.weight_normal {
  font-weight: 400; }

.weight_light {
  font-weight: 200; }

.font_size_huge {
  font-size: 60px; }

.font_size_large {
  font-size: 30px; }

.font_size_medium {
  font-size: 24px; }

.font_size_small {
  font-size: 18px; }

.font_size_tiny {
  font-size: 14px; }

.font_size_normal {
  font-size: 1rem; }

.font_inherit {
  font-family: inherit; }

.text_shadow {
  text-shadow: 1px 2px rgba(0, 0, 0, 0.4); }

.uppercase, .StepButton {
  text-transform: uppercase; }

.underline {
  text-decoration: underline; }

.white_space_no_wrap {
  white-space: nowrap; }

.hide_text {
  overflow: hidden;
  text-indent: -9999px; }

mark {
  background-color: #fdf9ee;
  color: #AF9128; }

.opacity_0, .FramePreviewToggle {
  opacity: 0; }

.opacity_1 {
  opacity: 0.1; }

.opacity_2 {
  opacity: 0.2; }

.opacity_3 {
  opacity: 0.3; }

.opacity_4 {
  opacity: 0.4; }

.opacity_5 {
  opacity: 0.5; }

.opacity_6, .faded, .FrameItem:hover .FramePreviewToggle {
  opacity: 0.6; }

.opacity_7 {
  opacity: 0.7; }

.opacity_8 {
  opacity: 0.8; }

.opacity_9 {
  opacity: 0.9; }

.overflow_auto {
  overflow: auto; }

.overflow_hidden, .Editor, .Steps, .Workspace {
  overflow: hidden; }

.overflow_x_hidden {
  overflow-x: hidden; }

.overflow_y_hidden {
  overflow-y: hidden; }

.overflow_scroll {
  overflow: scroll; }

[class^="media_"] img, [class^="media_"] video, [class^="media_"] object, [class^="media_"] iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

[class^="media_"] video, [class^="media_"] object, [class^="media_"] iframe {
  background: black; }

.media_16x9 {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden; }

.media_4x3 {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  height: 0;
  overflow: hidden; }

.media_3x4 {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 133.333%;
  height: 0;
  overflow: hidden; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.naked_button, .swatch, .StepCheckpoint {
  appearance: none;
  cursor: pointer;
  font-family: inherit; }
  .naked_button:focus, .swatch:focus, .StepCheckpoint:focus {
    outline: 1px dotted; }

.stripe-charge-form {
  margin: 0 auto; }

.short_divider {
  box-shadow: none;
  border: 0;
  width: 100%;
  height: 2px;
  max-width: 40px; }

.dotted_divider {
  background-image: url(/assets/images/icons/dotted.png);
  background-repeat: repeat-x; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media only screen and (max-width: 999px) {
  .dogear {
    transform: scale(0.5);
    transform-origin: right bottom; } }

.gallery_card {
  padding: 0;
  background: none;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all 300ms ease-out;
  cursor: pointer; }
  .gallery_card:hover, .gallery_card:focus {
    transform: translate3d(0, -10px, 0); }
    .gallery_card:hover .icon.search, .gallery_card:focus .icon.search {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0); }
  .gallery_card .icon.search {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 150ms ease-out;
    transform: translate3d(-50%, calc(-50% + 10px), 0); }

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 767px) {
    .hero {
      background-image: none !important; } }
  .hero.image {
    padding: 0 0 1.5rem; }
    @media only screen and (min-width: 768px) {
      .hero.image {
        padding: 10% 0; } }
  .hero.image.inverted {
    background-color: #212B36; }
    @media only screen and (min-width: 768px) {
      .hero.image.inverted {
        text-shadow: 0 0 5px black; } }
  .hero.scrim:before {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)); }
    @media only screen and (min-width: 768px) {
      .hero.scrim:before {
        display: block; } }
  .hero.inverted.scrim:before {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)); }

.hero__mobile_image {
  position: relative;
  margin-bottom: 1.5rem;
  min-height: 200px;
  height: 40vw;
  background-size: cover;
  background-position: center; }
  @media only screen and (min-width: 768px) {
    .hero__mobile_image {
      display: none !important; } }
  .hero__mobile_image img {
    display: block;
    width: 100%;
    height: auto; }

.home_old .home_hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28.57143rem;
  overflow: hidden;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 768px) {
    .home_old .home_hero {
      min-height: 42.85714rem;
      height: 60vh; } }
  @media only screen and (min-width: 1000px) {
    .home_old .home_hero {
      overflow: visible; } }

.home_old .home_hero_inner {
  max-width: 25rem;
  background-color: rgba(255, 255, 255, 0.9); }
  @media only screen and (min-width: 768px) {
    .home_old .home_hero_inner {
      background: none; } }
  @media only screen and (min-width: 1000px) {
    .home_old .home_hero_inner {
      max-width: 42.14286rem; } }

.home_old .hero_image {
  position: absolute;
  z-index: 0;
  max-width: 35.71429rem; }
  @media only screen and (min-width: 768px) {
    .home_old .hero_image {
      max-width: 53.57143rem; } }
  .home_old .hero_image.collages {
    top: -10rem;
    left: -20rem;
    transform: rotate(15deg); }
    @media only screen and (min-width: 768px) {
      .home_old .hero_image.collages {
        top: 50%;
        left: auto;
        right: calc(50% + 150px);
        transform: translateY(-50%) rotate(-15deg); } }
    @media only screen and (min-width: 1000px) {
      .home_old .hero_image.collages {
        right: calc(50% + 295px); } }
  .home_old .hero_image.art {
    top: 4.28571rem;
    right: -23.57143rem;
    transform: rotate(15deg); }
    @media only screen and (min-width: 768px) {
      .home_old .hero_image.art {
        top: 50%;
        left: calc(50% + 150px);
        right: auto;
        transform: translateY(-50%) rotate(-15deg); } }
    @media only screen and (min-width: 1000px) {
      .home_old .hero_image.art {
        left: calc(50% + 295px); } }

#subscribe {
  padding: 3rem 0 4.5rem; }
  @media only screen and (min-width: 1000px) {
    #subscribe {
      padding: 0;
      min-height: 80vh; } }

ol, ul {
  padding: 0 0 0 1.5rem;
  margin: 0 0 1.5rem; }

.logo {
  background-image: url(/assets/images/mydiywalllogo.png);
  background-size: 100% auto !important;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms ease-out;
  width: 190px;
  height: 27px;
  display: block;
  overflow: hidden;
  text-indent: -9999px; }
  .logo.medium {
    width: 170px;
    height: 24px; }
  .logo.small {
    width: 140px;
    height: 20px; }
  .logo .tagline {
    border-left: 1px solid #d0d1d1;
    padding-left: 0.7em;
    margin-left: 0.7em;
    font-size: 0.8em; }

.masonry .brick,
.masonry .brick_sizer {
  width: 50%;
  min-height: 220px;
  padding: 0 1.5rem; }
  @media only screen and (min-width: 768px) {
    .masonry .brick,
    .masonry .brick_sizer {
      width: 25%; } }
  @media only screen and (min-width: 1000px) {
    .masonry .brick,
    .masonry .brick_sizer {
      width: 16.666%; } }

.masonry .brick.two.wide, .masonry .brick.three.wide, .masonry .brick.four.wide {
  width: 50%; }

@media only screen and (min-width: 768px) {
  .masonry .brick.two.wide, .masonry .brick.three.wide {
    width: 25%; }
  .masonry .brick.four.wide {
    width: 33.333%; } }

@media only screen and (min-width: 1000px) {
  .masonry .brick.two.wide {
    width: 16.666%; } }

@media only screen and (max-width: 999px) {
  .masonry .brick.headline {
    width: 100% !important; } }

.outlined_button:before {
  transition: all 100ms ease-out; }

.outlined_button:hover:before, .outlined_button:focus:before {
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px; }

.raise_on_hover, .swatch {
  transition-duration: 100ms; }
  .raise_on_hover:hover, .swatch:hover {
    transform: translate3d(0, -5px, 0); }

.slide_right_on_hover {
  transition-duration: 100ms; }
  .slide_right_on_hover:hover {
    transform: translate3d(10px, 0, 0); }

.search-input {
  border: 0;
  background: none;
  box-shadow: none;
  color: inherit;
  font-weight: bold;
  font-size: 1.28571rem;
  font-family: inherit; }

/* Slider */
.slick-list {
  /** 
     * For some reason this was breaking the Designer app
     **/ }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  outline: none;
  z-index: 1;
  border: 1px solid #212B36;
  padding: 1rem;
  height: auto;
  width: auto;
  border-radius: 5px; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: #FFFFFF;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.3; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "Icons";
    font-size: 20px;
    line-height: 1;
    color: #212B36;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: "Icons";
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #212B36;
        opacity: 0.3;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #212B36;
      opacity: 1; }

.status_dot {
  width: 10px;
  height: 10px; }
  .status_dot.new {
    background: #0085DE; }
  .status_dot.approved {
    background: #79A843; }
  .status_dot.materialsSent {
    background: #ef9929; }
  .status_dot.framing {
    background: #ef9929; }
  .status_dot.ready {
    background: #ED6347; }
  .status_dot.complete {
    background: #0085DE; }

.swatch {
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 3.1rem;
  height: 3.1rem; }

.AddToCart {
  transition: all 100ms ease-out;
  width: 100%;
  border-radius: 5px; }
  @media only screen and (min-width: 1000px) {
    .Masthead .AddToCart {
      margin: -0.5em 0;
      min-width: 15vw; } }

.App {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100vh;
  position: relative; }

#Canvas {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 16px 32px -8px, rgba(0, 0, 0, 0.3) 0px 32px 64px -16px, rgba(0, 0, 0, 0.3) 0px 64px 128px -32px, rgba(0, 0, 0, 0.3) 0px 128px 256px -64px;
  outline: none;
  margin: auto;
  flex-shrink: 0; }
  #Canvas svg {
    overflow: visible; }

@media only screen and (min-width: 1000px) {
  .Editor {
    flex-direction: row;
    height: 0; } }

.FrameCategoryList {
  order: 0; }

.FrameList {
  flex-wrap: wrap;
  order: 1;
  margin: 1.5rem 0 0;
  justify-content: space-between;
  align-items: flex-start; }

.FrameItem {
  width: 47.5%;
  margin: 0.5rem 0;
  padding: 1rem 0;
  border: 1px solid transparent; }
  .FrameItem.is_active {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.FrameItemImage {
  width: 100%; }

.FramePreviewToggle {
  pointer-events: none;
  transform: translate3d(-50%, 0, 0); }
  .FrameItem:hover .FramePreviewToggle {
    pointer-events: all;
    transform: translate3d(-50%, -50%, 0); }

.GalleryCardPreview {
  overflow: auto;
  z-index: 1000; }
  .GalleryCardPreview img {
    display: block;
    max-width: 100%; }
  @media screen and (min-width: 767px) {
    .GalleryCardPreview {
      display: flex;
      align-items: center;
      justify-content: center; }
      .GalleryCardPreview img {
        margin: 0 1.5rem;
        max-width: 50%;
        max-height: 100%; } }

.Icon svg {
  width: 100%;
  height: auto;
  vertical-align: middle; }

.LibraryImage {
  position: relative;
  cursor: pointer;
  transition: all 150ms ease-out; }
  .LibraryImage:hover:after {
    opacity: 1; }
  .LibraryImage:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1), 0 32px 64px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: all 150ms ease-out; }
  .notouchevents .LibraryImage.draggable:not(.selectable):hover {
    transform: rotate(-5deg) scale(1.1); }
  .LibraryImage.selected img {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #0085DE; }
  .LibraryImage img {
    transition: box-shadow 100ms ease-out; }

.LibraryImage__checkbox {
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 0 0 2px #0085DE;
  top: 2px;
  left: 2px;
  width: 2rem;
  height: 2rem;
  opacity: 0; }
  .LibraryImage.selected .LibraryImage__checkbox,
  .LibraryImage:hover .LibraryImage__checkbox,
  .LibraryImage:focus .LibraryImage__checkbox {
    opacity: 1; }
  .LibraryImage__checkbox .icon {
    line-height: 1; }

.Masthead {
  padding: 1.5rem 1rem; }
  @media only screen and (min-width: 1000px) {
    .Masthead {
      padding: 1.5rem 0.5rem; } }

@media only screen and (min-width: 1000px) {
  .MatItem {
    margin-top: -4.64286rem; }
    .MatItem:first-child {
      margin-top: 0; } }

.MatItem__actions {
  margin: -2.5em 0 2em 1.5em;
  position: relative;
  z-index: 4; }
  @media only screen and (min-width: 1000px) {
    .MatItem__actions {
      margin: 0;
      position: absolute;
      left: 3rem;
      bottom: 5rem;
      z-index: 10; } }

.ProjectMatsList {
  margin: 0 -1.5rem; }
  @media only screen and (min-width: 1000px) {
    .ProjectMatsList {
      margin: 0 -3rem; } }

.MatColorButton {
  z-index: 4;
  right: 1.5rem; }
  @media only screen and (min-width: 1000px) {
    .MatColorButton {
      position: relative;
      right: auto; } }

@media only screen and (max-width: 999px) {
  .MatGraphic {
    margin-left: -3.57143rem; } }

@media only screen and (max-width: 999px) {
  .Openings {
    padding-bottom: 120px !important; } }

#RulersBasic {
  transition: opacity 150ms ease-out;
  transition-delay: 2000ms;
  opacity: 0; }
  .Workspace:hover #RulersBasic {
    transition-delay: 0ms;
    opacity: 1; }

.ruler_text {
  font-size: 10px;
  font-weight: bold;
  fill: #666; }

.ruler_tick {
  fill: #999; }

.Sidebar {
  order: 1; }
  @media only screen and (min-width: 1000px) {
    .Sidebar {
      order: 0; } }

.StepContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-x: hidden; }
  @media only screen and (min-width: 1000px) {
    .StepContent {
      flex-grow: 1;
      width: 25vw;
      min-width: 32.14286rem;
      overflow-y: auto; } }

.Sidebar__navigation {
  padding: 1rem 2rem;
  z-index: 11;
  order: 2; }
  @media only screen and (min-width: 1000px) {
    .Sidebar__navigation {
      position: relative;
      width: auto;
      bottom: auto;
      left: auto;
      order: initial;
      padding: 2rem; } }

.Section {
  order: 1; }
  @media only screen and (min-width: 1000px) {
    .Section {
      display: flex;
      flex-direction: column;
      order: initial;
      /* for Firefox */
      min-height: 0; } }

.SectionLabel {
  box-shadow: inset 0 -2px 0px -1px white; }

.Section__header {
  padding: 1.5rem; }
  @media only screen and (min-width: 1000px) {
    .Section__header {
      padding: 2rem 2rem 0; } }

.Section__content {
  padding: 1.5rem;
  /* for Firefox */
  min-height: 0; }
  @media only screen and (min-width: 1000px) {
    .Section__content {
      padding: 2rem 2rem 100px;
      overflow: auto; } }

.Section__taskbar {
  bottom: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 10;
  opacity: 0;
  transform: translate3d(0, 100%, 0); }
  .Section__taskbar.visible {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  @media only screen and (min-width: 1000px) {
    .Section__taskbar {
      position: absolute; } }

.Steps {
  justify-content: space-around;
  line-height: 1;
  z-index: 10;
  padding: 0.75rem 3rem;
  order: 0; }
  @media only screen and (min-width: 1000px) {
    .Steps {
      order: initial;
      padding: 1.5rem 3rem 0; } }

.StepButton {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 0.71429rem;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.6); }
  @media only screen and (min-width: 1000px) {
    .StepButton {
      font-size: 0.85714rem; } }
  .StepButton:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -0.75rem;
    left: calc(50% - 6px);
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
    @media only screen and (min-width: 1000px) {
      .StepButton:after {
        top: -1.5rem;
        left: calc(50% - 10px);
        border-bottom: none;
        border-top: 10px solid white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        transform: translate3d(0, -100%, 0); } }
  .StepButton.is_active, .StepButton:hover {
    color: white; }
    .StepButton.is_active:after, .StepButton:hover:after {
      transform: translate3d(0, 0, 0); }
    .StepButton.is_active .Icon > svg.layers > g, .StepButton:hover .Icon > svg.layers > g {
      fill: white; }
    .StepButton.is_active .Icon > svg.glass > g, .StepButton:hover .Icon > svg.glass > g {
      stroke: white; }
  .StepButton > .icon {
    font-size: 1.5em !important; }
  .StepButton > .Icon {
    width: 14px !important; }
    @media only screen and (min-width: 1000px) {
      .StepButton > .Icon {
        width: 18px !important; } }
    .StepButton > .Icon > svg * {
      transition: all 150ms ease-out; }

.StepCheckpoint {
  font-size: 1rem;
  cursor: pointer;
  text-transform: capitalize; }
  .StepCheckpoint .label {
    width: 3rem;
    height: 3rem; }
  .StepCheckpoint.complete:hover .label,
  .StepCheckpoint.complete:focus .label {
    background-color: rgba(255, 255, 255, 0.2); }
  .StepCheckpoint.disabled, .StepCheckpoint[disabled] {
    cursor: default;
    opacity: 0.3; }

svg.swatch {
  display: inline-block;
  width: 28px;
  height: 28px;
  vertical-align: middle; }
  svg.swatch.size-small {
    width: 16px;
    height: 16px; }
  svg.swatch.size-large {
    width: 48px;
    height: 48px; }

.WallcoreLogo .framer {
  font-size: 0.71429rem; }

@media only screen and (min-width: 1000px) {
  .WallcoreLogo {
    justify-content: center; }
    .WallcoreLogo .logo {
      margin: auto; }
    .WallcoreLogo .framer {
      font-size: 1rem; } }

.Workspace {
  height: 60vh; }
  @media only screen and (max-width: 999px) {
    #Setup .Workspace {
      height: auto; } }
  @media only screen and (min-width: 1000px) {
    .Workspace {
      height: 100%; } }
