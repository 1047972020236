$box_shadow : 0 1px 2px rgba(black, 0.1),
0 2px 4px rgba(black, 0.1),
0 4px 8px rgba(black, 0.1);

$box_shadow_strong: 0 1px 2px rgba(black, 0.1),
0 2px 4px rgba(black, 0.1),
0 4px 8px rgba(black, 0.1),
0 8px 16px rgba(black, 0.1),
0 16px 32px rgba(black, 0.1),
0 32px 64px rgba(black, 0.1);

$box_shadow_upper: 0 -2px 3px rgba(black, 0.3);
$box_shadow_light: 0 1px 2px rgba(black, 0.2);
$box_shadow_upper_light: 0 -1px 2px rgba(black, 0.2);

.box_shadow {
    box-shadow: $box_shadow;
}

.box_shadow_strong {
    box-shadow: $box_shadow_strong;
}

.box_shadow_upper {
    box-shadow: $box_shadow_upper;
}

.box_shadow_light {
    box-shadow: $box_shadow_light;
}

.box_shadow_upper_light {
    box-shadow: $box_shadow_upper_light;
}
