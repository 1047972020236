.Sidebar {
	@extend .display_flex; 
	@extend .flex_column;
	@extend .bg_white;
	@extend .border_t_lightest_grey;

	order: 1;
	@include media($computer) {
		order: 0;
	}
}