.AddToCart {
    transition: all 100ms ease-out;
    width: 100%;
    border-radius: 5px;
    
    .Masthead & {
        @include media($computer) {
            margin: -0.5em 0;
            min-width: 15vw;
        }
    }
}
