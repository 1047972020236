.Workspace {
    @extend .position_relative;
    @extend .overflow_hidden;
    @extend .flex_grow_1;
    @extend .bg_lightest_grey;
    height: 60vh;
    
    @include media($computer - 1px, max) {
        #Setup & {
            height: auto;
        }
    }
    @include media($computer) {
        height: 100%;
    }
}

// #Workspace_top_right {
//     transform: translate3d(100px, 0, 0);
// }
// #Workspace_bottom_right {
//     transform: translate3d(0, 70px, 0);
// }

// #Workspace_top_right,
// #Workspace_bottom_right {
//     transition: transform 150ms ease-out;
//     transition-delay: 2000ms;
//     .Workspace:hover & {
//         transform: translate3d(0%, 0, 0);
//         transition-delay: 0ms;
//     }
// }