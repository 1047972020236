.StepContent {
    @extend .position_relative;
    @extend .z_index_1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: hidden;
    
    @include media( $computer ) {
        flex-grow: 1;
        width: 25vw;
        min-width: rem(450);
        overflow-y: auto;
    }
    
}

.Sidebar__navigation {
    @extend .display_flex;
    @extend .bg_black;
    @extend .flex_shrink_0;
    @extend .position_fixed;
    @extend .width_100;
    @extend .bottom_0;
    @extend .left_0;
    padding: 1rem 2rem;
    z-index: 11;
    order: 2;
    
    @include media($computer) {
        position: relative;
        width: auto;
        bottom: auto;
        left: auto;
        order: initial;
        padding: 2rem;
    }
}

.Section {
    @extend .width_100;
    @extend .flex_grow_1;
    @extend .bg_white;
    order: 1;
    
    @include media( $computer ) {
        display: flex;
        flex-direction: column;
        order: initial;
        /* for Firefox */
        min-height: 0;
    }
}

.SectionLabel {
    @extend .transition_all;
    box-shadow: inset 0 -2px 0px -1px white;
}

.Section__header {
    padding: 1.5rem;
    @include media($computer){
        padding: 2rem 2rem 0;
    }
}

.Section__content {
    @extend .flex_grow_1;
    @extend .position_relative;
    @extend .display_block;
    // @extend .display_flex;
    // @extend .flex_column;
    padding: 1.5rem;
    /* for Firefox */
    min-height: 0;
    
    @include media($computer){
        padding: 2rem 2rem 100px;
        overflow: auto;
    }
}

.Section__taskbar {
    @extend .position_fixed;
    @extend .left_0;
    @extend .width_100;
    @extend .py_half;
    @extend .bg_white;
    @extend .display_flex;
    @extend .align_items_center;
    @extend .box_shadow_upper;
    @extend .transition_all;
    bottom: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    z-index: 10;
    opacity: 0;
    transform: translate3d(0,100%,0);
    
    &.visible {
        opacity: 1;
        transform: translate3d(0,0,0);
    }

    @include media($computer) {
        position: absolute;
    }
}