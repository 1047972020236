.raise_on_hover {
    @extend .transition_all;
    transition-duration: 100ms;
    @extend .cursor_pointer;
    &:hover {
        transform: translate3d(0,-5px,0);
    }
}

.slide_right_on_hover {
    @extend .transition_all;
    transition-duration: 100ms;
    @extend .cursor_pointer;
    &:hover {
        transform: translate3d(10px,0,0);
    }
}
