.LibraryImage {
    position: relative;
    cursor: pointer;
    transition: all 150ms ease-out;

    &:hover:after {
        opacity: 1;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height:100%;
        box-shadow: 0 1px 2px rgba(black, 0.1),
            0 2px 4px rgba(black, 0.1),
            0 4px 8px rgba(black, 0.1),
            0 8px 16px rgba(black, 0.1),
            0 16px 32px rgba(black, 0.1),
            0 32px 64px rgba(black, 0.1);
        opacity: 0;
        transition: all 150ms ease-out;
    }

    .notouchevents &.draggable:not(.selectable):hover {
        transform: rotate(-5deg) scale(1.1);
    }

    &.selected {
        img {
            box-shadow: 0 0 0 2px white, 0 0 0 4px $blue;
        }
    }

    img {
        transition: box-shadow 100ms ease-out;
    }
    
}

.LibraryImage__checkbox {
    @extend .transition_all;
    @extend .position_absolute;
    @extend .border_rounded;
    @extend .display_flex;
    @extend .align_items_center;
    @extend .justify_content_center;
    background-color: rgba(white, 0.75);
    box-shadow: inset 0 0 0 2px $blue;
    top: 2px;
    left: 2px;
    width: 2rem;
    height: 2rem;
    opacity: 0;
    
    .LibraryImage.selected & {
        @extend .bg_white;
        @extend .color_blue;
    }

    .LibraryImage.selected &, 
    .LibraryImage:hover &, 
    .LibraryImage:focus & {
        opacity: 1;
    }

    .icon {
        line-height: 1;
    }
}
