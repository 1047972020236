[class^="media_"] {
	img, video, object, iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;	
	}
	video, object, iframe {
		background: black;
	}
}

.media_16x9 {
	display: block;
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	height: 0;
	overflow: hidden;
}

.media_4x3 {
	display: block;
	position: relative;
	width: 100%;
	padding-top: 75%;
	height: 0;
	overflow: hidden;
}

.media_3x4 {
	display: block;
	position: relative;
	width: 100%;
	padding-top: 133.333%;
	height: 0;
	overflow: hidden;
}