.FrameCategoryList {
	order: 0
}

.FrameList {
	@extend .display_flex;
	flex-wrap: wrap;
	order: 1;
	margin: $spacer 0 0;
	justify-content: space-between;
	align-items: flex-start;
}

.FrameItem {
	@extend .border_rounded;
	@extend .display_block;
	@extend .flex_shrink_0;
	@extend .position_relative;
	@extend .transition_all;
	@extend .text_left;
	@extend .cursor_pointer;
	
	width: 47.5%;
	margin: 0.5rem 0;
	padding: 1rem 0;
	border: 1px solid transparent;
	

	&.is_active {
		@extend .box_shadow;
		@extend .border_green;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}

.FrameItemImage {
	@extend .display_block;
	@extend .width_auto;
	@extend .height_auto;
	@extend .transition_all;
	width: 100%;
}

.FramePreviewToggle {
	@extend .position_absolute;
	@extend .top_50;
	@extend .left_50;
	@extend .opacity_0;
	@extend .transition_all;
	pointer-events: none;
	transform: translate3d(-50%,0,0);
	
	.FrameItem:hover & {
		@extend .faded;
		pointer-events: all;
		transform: translate3d(-50%,-50%,0);
	}
}