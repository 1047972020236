.home_old {

    .home_hero {
        position: relative;
        display: flex;
        align-items:center; 
        justify-content:center;
        min-height: rem(400);   
        overflow: hidden;
        margin-bottom: 2rem;
        @include media($tablet) {
            min-height: rem(600);   
            height: 60vh;
        }
        @include media($computer) {
            overflow: visible;
        }
    }

    .home_hero_inner {
        @extend .px_1; 
        @extend .py_3; 
        @extend .text_center; 
        @extend .position_relative; 
        @extend .z_index_1;
        max-width: rem(350);
        background-color: rgba(white, 0.9);
        
        @include media($tablet) {
            background: none;
        }
        @include media($computer) {
            max-width: rem(590);
        }
    }

    .hero_image {
        position: absolute;
        z-index: 0;
        max-width: rem(500);
        @include media($tablet) {
            max-width: rem(750);
        }
        
        &.collages {
            top: rem(-140);
            left: rem(-280);
            transform: rotate(15deg);
            @include media($tablet) {
                top: 50%;
                left: auto;
                right: calc(50% + 150px);
                transform: translateY(-50%) rotate(-15deg);
            }
            @include media($computer) {
                right: calc(50% + 295px);
            }
        }
        &.art {
            top: rem(60);
            right: rem(-330);
            transform: rotate(15deg);
            @include media($tablet) {
                top: 50%;
                left: calc(50% + 150px);
                right: auto;
                transform: translateY(-50%) rotate(-15deg);
            }
            @include media($computer) {
                left: calc(50% + 295px);
            }
        }
    }

}
