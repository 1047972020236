.Steps {
    @extend .position_relative;
    @extend .bg_black;
    @extend .display_flex;
    @extend .overflow_hidden;
    @extend .flex_shrink_0;
    justify-content: space-around;
    line-height: 1;
    z-index: 10;
    padding: 0.75rem 3rem;
    order: 0;
    
    @include media($computer) {
        order: initial;
        padding: 1.5rem 3rem 0;
    }
    
    a {
        @extend .display_block;
        @extend .text_center;
        @extend .weight_bold;
        @extend .color_primary;
    }
}

.StepButton {
    @extend .align_items_center;
    @extend .border_none;
    @extend .cursor_pointer;
    @extend .display_flex;
    @extend .flex_column;
    @extend .justify_content_end;
    @extend .position_relative;
    @extend .text_center;
    @extend .transition_all;
    @extend .uppercase;
    @extend .weight_bold;
    @extend .width_auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: rem(10);
    letter-spacing: 0.05em;
    color: rgba(white, 0.6);
    
    @include media($computer) {
        font-size: rem(12);
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        @extend .transition_all;
        bottom: -0.75rem;
        left: calc(50% - 6px);
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        
        @include media($computer) {
            top: -1.5rem;
            left: calc(50% - 10px);
            border-bottom: none;
            border-top: 10px solid white;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            transform: translate3d(0,-100%,0);
        }

    }

    &.is_active, &:hover {
        color: white;
        &:after {
            transform: translate3d(0,0,0);
        }
        .Icon > svg.layers > g {
            fill: white;
        }
        .Icon > svg.glass > g {
            stroke: white;
        }
    }

    & > .icon { 
        font-size: 1.5em !important;
    }
    & > .Icon { 
        width: 14px !important; 
        @include media( $computer ) {
            width: 18px !important;
        }
        & > svg * {
            transition: all 150ms ease-out;
        }
    }

     
}

.StepCheckpoint {
    @extend .display_flex;
    @extend .align_items_center;
    @extend .flex_column;
    @extend .naked_button;
    @extend .bg_none;
    @extend .color_white;
    @extend .border_none;
    @extend .weight_bold;
    font-size: rem(14);
    cursor: pointer;
    text-transform: capitalize;
    
    .label {
        @extend .border_white;
        @extend .border_radius_50;
        @extend .display_flex;
        @extend .align_items_center;
        @extend .justify_content_center;
        @extend .mb_nudge;
        width: 3rem;
        height: 3rem;
    }
    
    &.complete {
        &:hover .label, 
        &:focus .label {
            background-color: rgba(white,0.2);
        }
    }

    &.active .label {
        @extend .bg_white;
        @extend .border_white;
        @extend .color_blue;
    }

    &.disabled,
    &[disabled] {
        cursor: default;
        opacity: 0.3;
    }
}