.masonry {
	@extend .clearfix;

	.brick,
	.brick_sizer {
		width: 50%;
		min-height: 220px;
		padding: 0 $spacer;
		
		@include media($tablet) { width: 25%; }
		@include media($computer) { width: 16.666%; }
	}
	
	.brick {
		&.two.wide,
		&.three.wide,
		&.four.wide { 
			width: 50%; 
		}
		@include media($tablet) { 
			&.two.wide,
			&.three.wide { 
				width: 25%;
			} 
			&.four.wide { 
				width: 33.333%;
			}
		}
		@include media($computer) { 
			&.two.wide { 
				width: 16.666%; 
			}
		}

		&.headline {
			@include media($computer - 1px, max) {
				width: 100% !important;
			}
		}
				
		// &.five.wide { width: 41.666%; }
		// &.six.wide { width: 50%; }
		// &.seven.wide { width: 58.333%; }
		// &.eight.wide { width: 66.666%; }
	}
}