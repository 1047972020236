$pointer-size: 10px;
$lg-pointer-size: 15px;

.pointer {
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        transition: all 250ms ease-out;
        color: white;
    }
    &.OnboardStep:after {
        color: $blue;
    }
    &.down:after {
        margin-top: 0;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top: $pointer-size solid;
        border-left: $pointer-size solid transparent;
        border-right: $pointer-size solid transparent;
    }
    &.left.top:after {
        margin-right: 0;
        top: 100%;
        left: 20px;
        border-top: 10px solid;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    &.right.top:after {
        margin-right: 0;
        top: 20px;
        right: 100%;
        border-right: $pointer-size solid;
        border-top: $pointer-size solid transparent;
        border-bottom: $pointer-size solid transparent;
    }
    &.right.center:after {
        margin-right: 0;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-right: $pointer-size solid;
        border-top: $pointer-size solid transparent;
        border-bottom: $pointer-size solid transparent;
    }
    &.left.center:after {
        margin-left: 0;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-left: $pointer-size solid;
        border-top: $pointer-size solid transparent;
        border-bottom: $pointer-size solid transparent;
    }
    &.right.bottom:after {
        margin-bottom: 0;
        bottom: 100%;
        right: 20px;
        border-bottom: $pointer-size solid;
        border-left: $pointer-size solid transparent;
        border-right: $pointer-size solid transparent;
    }
    &.left.bottom:after {
        margin-bottom: 0;
        bottom: 100%;
        left: 20px;
        border-bottom: $pointer-size solid;
        border-left: $pointer-size solid transparent;
        border-right: $pointer-size solid transparent;
    }
    &.large:after {
        border-width: $lg-pointer-size !important;
    }
}
