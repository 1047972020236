#RulersBasic {
    transition: opacity 150ms ease-out;
    transition-delay: 2000ms;
    opacity: 0;
    .Workspace:hover & {
        transition-delay: 0ms;
        opacity: 1;
    }
}

.ruler_text {
    font-size: 10px;
    font-weight: bold;
    fill: #666;
}
.ruler_tick {
    fill: #999;
}

