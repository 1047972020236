.GalleryCardPreview {
	overflow: auto;
	z-index: 1000;
	
	img {
		display: block;
		max-width: 100%;
	}
	
	@media screen and (min-width: 767px) {
		display: flex; 
		align-items: center;
		justify-content: center;

		img {
			margin: 0 1.5rem;
			max-width: 50%;
			max-height: 100%;
		}
	}
}